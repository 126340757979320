<template>
  <div class="">
    <b-row>
      <b-col md="12">
        <b-button
          class='right-button'
          variant="primary"
          :to="{ name: 'journal_edit', params: {customerId: customerId, journalId: 'new'}}">
          Skapa journalinlägg
        </b-button>
        <b-button
          variant="primary"
          class='right-button'
          v-if="employee.user.is_staff"
          :to="{ name: 'journals', params: {customerId: customerId}}">
          Lista journal
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">

        <b-form-group
          label-for="input-start"
          :label="$gettext('Från')">
          <b-input-group>
            <b-form-input
              id="input-start"
              v-model="start"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
              ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="start"
                button-only
                right
                locale="sv"
                v-bind="datepickerLabels['sv'] || {}"
                start-weekday="1"
                aria-controls="input-start"
                @context="onContext"
                ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label-for="input-end"
          :label="$gettext('Till')">
          <b-input-group>
            <b-form-input
              id="input-end"
              v-model="end"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
              ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="end"
                button-only
                right
                locale="sv"
                v-bind="datepickerLabels['sv'] || {}"
                start-weekday="1"
                aria-controls="input-end"
                @context="onContext"
                ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

      </b-col>
    </b-row>
    <b-card v-for="item in journals"
            v-if="journals"
            :key="item.id"
            :title="employeeName(item.employee)"
            :sub-title="journalTime(item.start, item.end)"

            >
            <b-card-text class='description'>
              {{ item.description }}

              <b-list-group
                v-if='journalsteps'
                >
                <b-list-group-item
                  v-for="step in filteredJournalSteps(item.id)"
                  :key="step.id">
                  <div v-if="getCareplanstep(step.careplanstep).steptype==1 && step.value!=0">
                    <b-icon icon="check" scale="2" variant="success"/>
                      {{ getCareplanstep(step.careplanstep).description }}
                  </div>
                  <div v-if="getCareplanstep(step.careplanstep).steptype==2">
                      {{ getCareplanstep(step.careplanstep).description }}:
                      <span v-if="step.value==1">
                        Ja
                      </span>
                      <span v-if="step.value==2">
                        Nej
                      </span>
                  </div>
                  <div v-if="getCareplanstep(step.careplanstep).steptype==3">
                    {{ getCareplanstep(step.careplanstep).description }}: {{ parseFloat(step.value) }}
                  </div>
                  <div v-if="getCareplanstep(step.careplanstep).steptype==4">
                    {{ getCareplanstep(step.careplanstep).description }}: {{ getChoice(step.careplanstep, step.value) }}
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card-text>
            <b-button
              class="right-button"
              variant="primary"
              v-if="employee.user.is_staff||employee.id==item.employee"
              @click="editJournal(item.id)">
              Editera
            </b-button>
              <b-button
                class="right-button"
                variant="warning"
                v-if="(employee.user.is_staff||employee.id==item.employee)&&item.edited"
                :to="{ name: 'journal_history', params: {uuid: item.uuid.toString()}}"
                >
                Historik
              </b-button>
    </b-card>
    <b-modal
      id="journal-modal"
      size="lg"
      hide-footer>
      <journal-edit
        :customerId="customerId"
        :journalId="editJournalId.toString()"
        v-on:done="fetch"
        />
    </b-modal>
  </div>
</template>

<script>
import { sub} from 'date-fns'
import {
  customerService,
  customerAttachmentService,
  journalService,
  journalStepService,
  carePlanStepService,
  employeeService,
  stepChoiceService,
} from '../_services';
import JournalEdit from '../components/JournalEdit'
import { utils } from '../_mixins/utils.js';

export default {
  name: 'Journals',
  mixins: [utils],
  components: {
    JournalEdit,
  },
  props: {
    customerId: {
      type: String,
    },
  },
  data () {
    const now = new Date()
    const today = this.toDate(now)
    const month_ago = this.toDate(sub(now, {months: 1}))
    return {
      journals: undefined,
      employees: [],
      careplansteps: [],
      journalsteps: undefined,
      editJournalId: 0,
      stepchoices: [],
      start: month_ago,
      end: today,
    }
  },
  computed: {
    employee () {
      return this.$store.state.employee.employee
    },
  },
  created () {
    var self = this
    self.fetch()
  },
  watch: {
    start: function(val) {
      this.get_date_data()
    },
    end: function(val) {
      this.get_date_data()
    },
  },
  methods: {
    async fetch() {
      var self = this
      try {
        self.$bvModal.hide('journal-modal')
        self.journalsteps = await journalStepService.getAll(self.customerId)
        self.employees = await employeeService.getAll(true)
        self.careplansteps = await carePlanStepService.getAll(self.customerId, true)
        self.careplansteps.forEach(async function(careplanstep) {
          self.stepchoices[careplanstep.id] = await stepChoiceService.getAll(careplanstep.id)
        })
        self.get_date_data()
      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.$router.push({ name: 'customers'})
      }
    },
    async get_date_data() {
      var self = this
      var startDate = new Date(self.start + 'T00:00:00')
      var endDate = new Date(self.end + 'T23:59:59')
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        self.journals = []
        return
      }
      self.journals = await journalService.getAll(
        self.customerId, undefined, undefined,
        startDate, endDate,
      )
    },
    filteredJournalSteps(journal) {
      var self = this
      let steps = this.journalsteps.filter((step) => {
        if (step.journal === journal && (step.value != undefined)) {
          if (parseFloat(step.value) == 0 && self.getCareplanstep(step.careplanstep).steptype==1) {
            // Skip check type with false value
            return false
          }
          return true
        }
        return false
      })
      return _.orderBy(
        steps,
        'careplanstep');
    },
    getCareplanstep(step_id) {
      var self = this
      if (self.careplansteps) {
        var careplanstep = self.careplansteps.find(x => x.id === step_id)
        if (careplanstep) {
          return careplanstep
        }
      }
      return ''
    },
    getChoice(step_id, value){
      var self = this
      var result = "error: " + parseInt(value)
      self.stepchoices[step_id].forEach(choice => {
        if (parseInt(choice.value) == parseInt(value)){
          result = choice.description
        }
      })
      return result
    },
    editJournal(id) {
      this.editJournalId = id
      this.$bvModal.show('journal-modal')
    },
  },
}
</script>
