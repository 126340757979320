import { authHeader } from '../_helpers';
import { router } from '../_helpers';
import { store } from '../_store';
import axios from "axios";

function getParams(params){
  if(params == undefined){
    return ''
  }
  let result = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (value == undefined){
      continue
    } else if (value instanceof Date){
      result.append(key, value.toISOString());
    }
    else{
      result.append(key, value);
    }
  }

  return '?' + result
}

export async function getApiRequest(path, params, headers=authHeader(), responseType='json', version) {
  let requestOptions = {
    method: 'GET',
    headers: headers,
    params: params,
    responseType: responseType,
  };
  let version_string = '/api';
  if (version == 2) {
    version_string = '/api2';

  }

  var url = version_string + path
  let response = await axios(url, requestOptions)
    .catch(async function(error){
      if (error.response){
        if (error.response.status == "401"){
          // store.dispatch('alert/error', error.response.statusText, { root: true })
          console.error(error.response)
          localStorage.removeItem('user');
          router.push('/login');
          // this.$router.push({ name: 'users'})
          // self.$store.dispatch('authentication/logout');

        }else {
          return Promise.reject(error.response.data);
        }
      } else {
        console.error(error)
      }
      return Promise.reject({'error': error});
    })
  return response.data
}

function clearAlert(){
  store.dispatch('alert/clear', { root: true });
}

export function getApiRequestAsync(path, params, version) {
  return new Promise((resolve, reject) => {
    let requestOptions = {
      method: 'GET',
      headers: authHeader(),
      params: params,
    };
    let version_string = '/api';
    if (version == 2) {
      version_string = '/api2';

    }
    let response = axios(version_string + path, requestOptions)
      .then(function(result) {
        resolve(result.data)
      })
      .catch(function(error){
        if(error.response){
          if (error.response.status == "401"){
            localStorage.removeItem('user');
            router.push('/login');
            // this.$router.push({ name: 'users'})
            // self.$store.dispatch('authentication/logout');

          }
        }else{
          console.error(error)
        }
        reject({'data': false});
      })
  })
}

export async function postApiRequest(path, data, params) {
  let requestOptions = {
    method: 'POST',
    headers: authHeader(),
    params: params,
    data: data,
  };
  let response = await axios('/api' + path, requestOptions)
    .catch(async function(error){
      console.error(error.response)
      return Promise.reject(error.response.data);
    })
  return response.data
}

export async function postFileApiRequest(path, data, params) {
  let headers = authHeader()
  headers['Content-Type'] = 'multipart/form-data'

  let requestOptions = {
    method: 'POST',
    headers: headers,
    params: params,
    data: data,
  };

  let response = await axios('/api' + path, requestOptions)
    .catch(async function(error){
      console.error(JSON.stringify(error))
      return Promise.reject(error.response.data);
    })
  return response.data
}

export async function putApiRequest(path, data, params) {
  let requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    params: params,
    data: data,
  };
  let response = await axios('/api' + path, requestOptions)
    .catch(async function(error){
      console.error(error.response)
      return Promise.reject(error.response.data);
    })
  return response.data
}

export async function deleteApiRequest(path, params, data={}) {
  let requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    params: params,
    data: data,
  };
  let response = await axios('/api' + path, requestOptions)
    .catch(async function(error){
      console.error(error.response)
      return
    })
  return response.data
}
