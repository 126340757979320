<template>
  <b-container fluid>
    <div class="about">
      <h1>Deductive Care</h1>
      <p>Deductive Care is a healthcare management application for healthcare providers.</p>
    </div>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'About',
  components: {
  },
};
</script>
