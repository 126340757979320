<script charset="utf-8">
import VueCharts from 'vue-chartjs'
import { Bar, Line } from 'vue-chartjs'

import {
  carePlanStepService,
  journalService,
  journalStepService,
} from '../_services';
import { utils } from '../_mixins/utils.js';

export default {
  name: 'CarePlanData',
  extends: Line,
  mixins: [utils],
  props: {
    dataTable: {
      type: Array,
    },
  },
  data () {
    return {
    }
  },
  watch: {
    dataTable: function(val) {
      this.render()
    },
  },
  created() {
  },
  mounted() {
    var self = this
    this.render()
  },
  methods: {
    async fetch () {
      var self = this
      self.render()
    },
    render(){
      var self = this
      self.renderChart(
        {
          datasets: [
            {
              label: 'data',
              data: self.dataTable,
              backgroundColor: 'hsl(80, 20%, 50%)',
              borderColor: 'hsl(80, 50%, 50%)',
              fill: false,
            }
          ],
        },
        {
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day'
              }
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      )
    },
  },


}
</script>
