// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/relative/message/'

export const relativeMessageService = {
  getAll,
  get,
  post,
  put,
};

async function getAll(customer_id) {
  const params = {
    customer_id: customer_id,
  }
  return getApiRequest(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

