<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col md="8"
             v-if="employee"
        >
        <b-button
          class="right-button"
          variant="primary"
          v-if="employee.user.is_staff"
          :to="{ name: 'customer_edit', params: {customerId: 'new'}}">
          Skapa Ny Kund
        </b-button>
        <h1>Kunder</h1>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              content-cols-sm
              content-cols-lg="9"
              label-for="input-filtername"
              :label="$gettext('Sök')">
              <b-form-input
                id="input-filtername"
                v-model="filterName"
                type="text" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="3"
              label-cols-lg="5"
              label-align-sm="right"
              content-cols-sm="9"
              content-cols-md="7"
              label="Visa grupp"
              label-for="input-horizontal"
              >
              <v-select
                v-model="filtergroup"
                :options="groupOptions"
                :clearable="false"
                label="text"
                :reduce="option => option.value"
                >
              </v-select>
            </b-form-group>
            <b-form-group
              v-if="employee.user.is_staff"
              id="fieldset-horizontal"
              label-cols-sm="3"
              label-cols-lg="5"
              label-align-sm="right"
              content-cols-sm="9"
              content-cols-md="7"
              label="Visa inaktiva"
              label-for="input-horizontal"
              >
              <v-select
                v-model="filterinactive"
                :options="activeOptions"
                :clearable="false"
                label="text"
                :reduce="option => option.value"
                >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table hover stacked='md' :fields="fields" :items="customers"

          filter="criteria"
          :filter-function="filterCustomers"
          >
          <template v-slot:cell(name)="data">
            {{ data.item.surname + ', ' +  data.item.firstname }}
            <b-badge variant="danger" v-if="data.item.active == false">Inaktiv</b-badge>
          </template>
          <template v-slot:cell(group)="data">
            {{ groupName(data.item.group) }}
          </template>
          <template v-slot:cell(edit)="data">
            <b-button-group>
              <b-button
                variant="primary"
                :to="{ name: 'customer', params: {customerId: data.item.id.toString()}}">
                Visa
              </b-button>
              <b-button
                variant="primary"
                v-if="data.item.active"
                :to="{ name: 'journal_edit', params: {customerId: data.item.id.toString(), journalId: 'new'}}">
                Skapa journalinlägg
              </b-button>
              <b-button
                variant="primary"
                v-if="employee.user.is_staff && data.item.active"
                :to="{ name: 'invoice', params: {customerId: data.item.id.toString()}}">
                Fakturering
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { customerService, groupService } from '../_services';
import vSelect from 'vue-select';

export default {
  name: 'CustomersPage',
  components: {
    vSelect,
  },
  props: {
    groupId:{
      type: String,
      default: "false",
    },
  },
  data () {
    return {
      customers: undefined,
      fields: [
        {key: 'name', label: 'Namn'},
        {key: 'group', label: 'Grupp'},
        {key: 'edit', label: ''},
      ],
      filtergroup: false,
      groupOptions: [
        { value: false, text: 'Alla' },
        { value: null, text: 'Utan grupp' },
      ],
      filterinactive: true,
      filterName: '',
      activeOptions: [
        { value: false, text: 'Ja' },
        { value: true, text: 'Nej' },
      ],

    }
  },
  computed: {
    employee () {
      return this.$store.state.employee.employee
    },
  },
  watch: {
    filtergroup: function(val){
    var self = this
      if (val){
        if (self.filtergroup != self.groupId){
          this.$router.push({ name: 'customers-filtered', params: {groupId: val.toString()}})
        }
      } else if (val == null && self.groupId != "-1"){
        this.$router.push({ name: 'customers-filtered', params: {groupId: "-1"}})

      } else {
        this.$router.push({ name: 'customers'})
      }
    },
  },
  mounted () {
    var self = this
    if (self.groupId == "-1"){
      self.filtergroup = null
    }else if (self.groupId == "false"){
      self.filtergroup = false
    }else if (self.groupId != undefined){
      self.filtergroup = parseInt(self.groupId)
    }
    self.fetch()
  },
  methods: {
    async fetch() {
      var self = this
      try{
        self.groups = await groupService.getAll()
        self.groupOptions = [
          { value: false, text: 'Alla' },
          { value: null, text: 'Utan grupp' },
        ]
        self.groups.forEach(element => {
          self.groupOptions.push({
            value: element.id,
            text: element.name,
          })
        })
        self.customers = await customerService.getAll(true)
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    groupName(Id) {
      var self = this
      var group = self.groups.find(x => x.id === Id)
      if (group) {
        return group.name
      }
      return ''
    },
    filterCustomers(row, filter) {
      var self = this
      if( this.filtergroup != false) {
        if (row.group !== this.filtergroup){
          return false
        }
      }
      if(this.filterinactive != null){
        if( this.filterinactive != row.active){
          return false
        }
      }
      var name =  row.surname + ', ' +  row.firstname
      if( !name.toLowerCase().includes(self.filterName.toLowerCase()) ){
        return false
      }

      return true
    },
  },
}
</script>
