// import config from 'config';
import axios from "axios";
import { getApiRequest, getApiRequestAsync, postApiRequest, postFileApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';
import { authHeader } from '../_helpers';

const path = '/timereport/'

export const timereportService = {
  getAll,
  get,
  post,
  put,
  remove,
  get_xml,
};

function getAll(employee_id, start, end, tags=false) {
  const params = {
    employee_id: employee_id,
    start: start,
    end: end,
    tags: tags,
  }
  return getApiRequestAsync(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

function get_xml(start, end) {
  var headers = authHeader()
  headers['Accept'] = '*/*'
  var responseType = 'arraybuffer'
  const params = {
    start: start,
    end: end,
  }
  return getApiRequest(path + 'get-xml/', params, headers, responseType)
}
