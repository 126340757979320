
<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col
        md="8"
        offset-md="1"
        v-if="attachments"
        >
        <h3>
          <translate>Meddelanden</translate>
        </h3>
          <b-card
            v-for="item in messages"
            :key="item.id"
            :header="new Date(item.creation_time)| dateFormat('YYYY-MM-DD HH:mm')"
            header-tag="header"
            :footer="employeeName(item.employee)"
            footer-tag="footer"
            title=""
            >
            <b-row no-gutters>
              <b-col md="6">
                <b-card-text>{{ item.description }}
                  {{item.attachment}}
                </b-card-text>
              </b-col>
              <b-col md="6" v-if="messageAttachment(item.id)" >
                <b-card-img :src="messageAttachment(item.id).attachment" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
            </b-row>
          </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>

import {
  employeeService,
  relativeMessageService,
  relativeMessageAttachmentService,
} from '../_services';
import { utils } from '../_mixins/utils.js';

export default {
  name: 'RelativeMessages',
  mixins: [utils],
  props: {
    customerId: {
      type: String,
    },
  },
  data () {
    return {
      employees: undefined,
      messages: [],
      attachments: undefined,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
  },
  methods: {
    async fetch() {
      var self = this
      try {
        self.employees = await employeeService.getAll()
        self.messages = await relativeMessageService.getAll(self.customerId)
        self.attachments = await relativeMessageAttachmentService.getAll(self.customerId)
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    employeeName(employeeId) {
      var self = this
       var employee = self.employees.find(x => x.id === employeeId)
       if (employee) {
         return employee.user.firstname + ' ' + employee.user.surname
       }
      return ''
    },
    messageAttachment(message_id){
      var self = this
      if (self.attachments) {
        var attachment = self.attachments.find(x => x.relative_message === message_id)
        return attachment
      }
      return undefined
    },
  },
}
</script>

<style>
.card {
  page-break-inside: avoid;
  break-inside: avoid;
}
.card-img {
  height:auto;
  width:auto;
  max-width: 300px;
}
</style>
