<template>
  <b-container fluid id="user" >
    <b-row class="justify-content-center mt-3">
      <b-col md="4"
             v-if="loaded==true && current_employee">
        <h1>Personalinformation</h1>
        <b-form
          v-if="employee||newUser"
          @submit.prevent="handleSubmit">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-email"
            :label="$gettext('Epost')">
            <b-form-input
              id="input-email"
              required
              autocomplete="off"
              v-model="employee.user.email"
              type="email"
              :state="is_ok('email', error.user)"
              :placeholder="$gettext('Fyll i epost')">
            </b-form-input>
            <b-form-invalid-feedback
              v-for="item in error.user.email"
              :key="item">
              {{ item }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-password"
            :label="$gettext('Lösenord')">
            <b-form-input
              id="input-password"
              type="password"
              :required="newUser"
              autocomplete="new-password"
              v-model="employee.user.password"
              :state="is_ok('password', error.user)"
              :placeholder="$gettext('Fyll i ett lösenord')"/>
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.user.password"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-password-verify"
            :label="$gettext('Repetera Lösenord')">
            <b-form-input
              id="input-password-verify"
              type="password"
              :required="newUser"
              autocomplete="new-password"
              v-model="password_verify"
              :state="is_ok('password_verify', error)"
              :placeholder="$gettext('Fyll i lösenordet igen')"/>
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.password_verify"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-force_password_change"
            :label="$gettext('Tvinga lösenordsbyte')">
            <b-form-checkbox
              id="input-force_password_change"
              switch
              v-model="employee.user.force_password_change"
              :state="is_ok('force_password_change', error.user)"
              />
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.user.force_password_change"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>

          <web-authn
            v-if="current_employee.id == employee.id"
            />
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-firstname"
            :label="$gettext('Förnamn')">
            <b-form-input
              id="input-firstname"
              required
              v-model="employee.user.firstname"
              :state="is_ok('firstname', error.user)"
              type="text" />
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.user.firstname"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-surname"
            :label="$gettext('Efternamn')">
            <b-form-input
              id="input-surname"
              required
              v-model="employee.user.surname"
              :state="is_ok('surname', error.user)"
              type="text" />
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.user.surname"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-identification"
            :label="$gettext('ID')">
            <b-form-input
              id="input-identification"
              v-model="employee.identification"
              :state="is_ok('identification')"
              type="text" />
              <b-form-invalid-feedback
                v-for="item in error.identification"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-phone"
            :label="$gettext('Telefonnummer')">
            <b-form-input
              id="input-phone"
              v-model="employee.phone_number"
              :state="is_ok('phone_number')"
              type="tel" />
              <b-form-invalid-feedback
                v-for="item in error.phone_number"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-phone2"
            :label="$gettext('Telefonnummer 2')">
            <b-form-input
              id="input-phone2"
              v-model="employee.phone_number2"
              :state="is_ok('phone_number2')"
              type="tel" />
              <b-form-invalid-feedback
                v-for="item in error.phone_number2"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-admin"
            v-if="current_employee.user.is_staff||current_employee.user.is_superuser"
            :label="$gettext('Administratör')">
            <b-form-checkbox
              id="input-admin"
              switch
              v-model="employee.user.is_staff"
              :state="is_ok('admin', error.user)"
              />
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.user.is_staff"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-if="current_employee.user.is_superuser"
            label-for="input-is_superuser"
            :label="$gettext('Super administratör')">
            <b-form-checkbox
              id="input-is_superuser"
              switch
              v-model="employee.user.is_superuser"
              :state="is_ok('is_superuser', error.user)"
              />
              <b-form-invalid-feedback
                v-if="error.user"
                v-for="item in error.user.is_superuser"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-if="groups&&(current_employee.user .is_staff||current_employee.user.is_superuser)"
            label-for="input-group"
            :label="$gettext('Grupper')">
            <v-select
              v-model="employee.customer_groups"
              :options="groupOptions"
              label="text"
              :reduce="option => option.value"
              multiple>
            </v-select>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-if="organisations&&current_employee.user .is_superuser"
            label-for="input-organisation"
            :label="$gettext('Organisation')">
            <v-select
              v-model="employee.user.organisation"
              :options="organisationOptions"
              placeholder='Välj organisation'
              label="text"
              :reduce="option => option.value"
              >
            </v-select>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-working-hours"
            :label="$gettext('Arbetstid')">
            <b-input-group append="%">
              <b-form-input
                id="input-working-hours"
                v-model="percentage"
                :state="is_ok('working_hours')"
                type="number"
                step="any"
                />
            </b-input-group>
              <b-form-invalid-feedback
                v-for="item in error.working_hours"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-active"
            :label="$gettext('Aktiv')">
            <b-form-checkbox
              id="input-active"
              switch
              v-model="employee.active"
              :state="is_ok('active', error.active)"
              />
              <b-form-invalid-feedback
                v-if="error.active"
                v-for="item in error.active"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-row>
            <b-col  offset-md="3">
              <div class="">
                <b-button
                  variant="primary"
                  type="submit"
                  >
                  <translate>Spara</translate>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="secondary"
                  @click="back"
                  :disabled="saving">
                  <translate>Ångra</translate>
                </b-button>
              </div>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
        </b-form>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import vSelect from 'vue-select';
import {
  employeeService,
  groupService,
  organisationService,
} from '../_services'
import WebAuthn from '../components/WebAuthn'

export default {
  name: 'UserPage',
  components: {
    WebAuthn,
    vSelect,
  },
  props: {
    userId: {
      type: String,
    },
  },
  data () {
    return {
      employee: {
        'user': {
          'password': '',
          'firstname': '',
          'surname': '',
          'force_password_change': true,
        },
        'customer_groups': [],
        'active': true,
      },
      password_verify: undefined,
      percentage: 100,
      groups: undefined,
      groupOptions: [],
      organisations: undefined,
      organisationOptions: [ ],
      error: {'user': {}},
      newUser: false,
      saving: false,
      loaded: false
    }
  },
  computed: {
    current_employee () {
      return this.$store.state.employee.employee
    },
    organisation () {
      return  this.$store.getters['employee/organisation']
    },
  },
  created () {
    var self = this
    if (self.userId != 'new'){
      self.fetch()
    } else {
      self.newUser = true
    }
    self.fetchGroups()
    self.loaded = true
  },
  watch: {
    '$route.params': {
      handler(newValue) {
        if (self.userId != 'new' && self.userId){
          this.fetch()
        }
      },
      immediate: true,
    }
  },
  methods: {
    async fetch() {
      var self = this
      try {
        self.employee = await employeeService.get(self.userId)
        self.percentage = (self.employee.working_hours / self.organisation.working_hours * 100).toFixed()
      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.$router.push({ name: 'users'})
      }
    },
    async fetchGroups() {
      var self = this
      self.groups = await groupService.getAll()
      self.groups.forEach(element => {
        self.groupOptions.push({
          value: element.id,
          text: element.name,
        })
      })
      self.organisations = await organisationService.getAll()
      self.organisations.forEach(element => {
        self.organisationOptions.push({
          value: element.id,
          text: element.name,
        })
      })
    },
    async handleSubmit () {
      var self = this
      if (self.employee.user.password != self.password_verify) {
        self.error = {
          password_verify: {'error': 'Lösenordet är inte samma'},
          user: {},
        }
        return
      }
      self.saving = true
      self.employee.working_hours = (self.percentage * self.organisation.working_hours / 100).toFixed(2)
      try {
        if (self.newUser === true) {
          await employeeService.post(self.employee)
        } else {
          await employeeService.put(self.userId, self.employee)
        }
        self.$store.dispatch('employee/loadEmployee', { user_id: self.current_employee.id });
        self.$router.push({ name: 'users'})
      }catch(err){
        self.error=err
      }
      self.saving = false
    },
    back() {
      this.$router.push({ name: 'users'})
    },
    is_ok(attribute, error=this.error){
      if (attribute in error){
        return false
      }
      return null
    },
  },
}
</script>
