<template>
  <div v-if="employee" >
    <h1>Tidrapport för {{ employee.user.firstname }} {{ employee.user.surname }}</h1>
    <form
      class="box"
      @submit.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-form-group
            label-for="input-date"
            :label="$gettext('Datum')">
            <b-input-group>
              <b-form-input
                id="input-date"
                v-model="date"
                :state="is_ok('date')"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="date"
                  button-only
                  right
                  locale="sv"
                  v-bind="labels['sv'] || {}"
                  start-weekday="1"
                  aria-controls="input-date"
                  @context="onContext"
                  ></b-form-datepicker>
              </b-input-group-append>
              <b-form-invalid-feedback
                v-for="item in error.date"
                :key="item"
                >
                {{ item }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label-for="input-start"
                :label="$gettext('Start')">
                <b-input-group class="mb-3">
                  <vue-timepicker
                      id="input-start"
                      v-model="start"
                      auto-scroll
                      manual-input
                      input-width="8em"
                      />
                  <b-form-invalid-feedback
                      v-for="item in error.start"
                      :key="item"
                      >
                      {{ item }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="input-end"
                :label="$gettext('Slut')">
                <b-input-group class="mb-3">
                  <vue-timepicker
                      id="input-end"
                      v-model="end"
                      auto-scroll
                      manual-input
                      input-width="8em"
                      />
                  <b-form-invalid-feedback
                      v-for="item in error.end"
                      :key="item"
                      >
                      {{ item }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label-for="input-lunch"
            :label="$gettext('Lunch paus')"
          >
            <b-form-radio
              v-model="lunch"
              name="lunch"
              value="0"
            >
              <translate>Ingen</translate>
            </b-form-radio>
            <b-form-radio
              v-model="lunch"
              name="lunch"
              value="30"
            >
              <translate>30 Minuter</translate>
            </b-form-radio>
            <b-form-radio
              v-model="lunch"
              name="lunch"
              value="60"
            >
              <translate>60 Minuter</translate>
            </b-form-radio>
          </b-form-group>
          <b-form-group
              v-if="jobfunctions&&organisation.job_functions"
              label-for="input-job-function"
              :label="$gettext('Funktion')">
            <v-select
                id="input-job-function"
                placeholder='Ingen funktion vald'
                v-model="timereport.jobfunction"
                :options="jobfunctionOptions"
                label="text"
                :reduce="option => option.value"
                >
            </v-select>
              <b-form-invalid-feedback
                  :force-show="true"
                  v-for="item in error.jobfunction"
                  :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-for="description"
            :label="$gettext('Beskrivning')">
            <b-form-textarea
              id="description"
              v-model="timereport.description"
              :state="is_ok('description')"
              placeholder="Enter something..."
              rows="10"
              max-rows="4"
              ></b-form-textarea>
            <b-form-invalid-feedback
              v-for="item in error.description"
              :key="item">
              {{ item }}
            </b-form-invalid-feedback>
          </b-form-group>


        </b-col>
      </b-row>
      <b-row>
        <b-col>

          <div class="">
            <b-button
              variant="primary"
              type="submit"
              :disabled="saving">
              <translate>Spara</translate>
            </b-button>
            <b-button
              class="ml-1"
              variant="secondary"
              @click="done"
              :disabled="saving">
              <translate>Ångra</translate>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>

import { utils } from '../_mixins/utils.js';
import vSelect from 'vue-select';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {
  timereportService,
  jobfunctionService,
  employeeService,
} from '../_services';
import { is_ok } from '../_helpers';

export default {
  name: 'TimeReport',
  mixins: [utils],
  components: {
    vSelect,
    VueTimepicker,
  },
  props: {
    employeeId: {
      type: String,
      default: undefined,
    },
    timereportId: {
      type: String,
      default: undefined,
    },
    start_prop: {
      type: Date,
      default: undefined,
    },
    end_prop: {
      type: Date,
      default: undefined,
    },
    occurrence: {
      type: Number,
      default: undefined,
    },
    jobfunction: {
      type: String,
      default: undefined,
    },
    lunch_prop: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      error: {},
      employee: undefined,
      date: undefined,
      start: undefined,
      end: undefined,
      timereport: {
        employee: undefined,
        description: undefined,
      },
      lunch: 0,
      saving: false,
      jobfunctionOptions: [],
      labels: {
        sv: {
          labelPrevYear: 'Förra året',
          labelPrevMonth: 'Förra månaden',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nästa månad',
          labelNextYear: 'Nästa år',
          labelToday: 'Idag',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Inget datum valt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Använd piltangenterna för att navigera i kalendern'
        },
      },
    }
  },
  created () {
    var self = this

    self.set_time()
    self.fetch()

  },
  computed: {
    user () {
      return this.$store.state.authentication.token
    },
    organisation () {
      return  this.$store.getters['employee/organisation']
    },
  },
  methods: {
    is_ok(attribute){
      var self = this
      if (attribute in self.error){
        return false
      }
      return null
    },
    set_time() {
      var self = this

      const now = new Date()
      let start_date = now
      if (self.start_prop == undefined) {
        self.start = self.datetimeToTime(now)
      }else {
        self.start = self.datetimeToTime(self.start_prop)
        start_date = self.start_prop
      }
      if (self.end_prop == undefined) {
        self.end = self.datetimeToTime(now)
      }else {
        self.end = self.datetimeToTime(self.end_prop)
      }

      var dt = start_date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      self.date = start_date.getFullYear() + '-' + (start_date.getMonth()+1).toString().padStart(2, "0") + '-' + dt
    },
    async fetch() {
      var self = this
      try {
        if (self.timereportId != 'new'){
          self.timereport = await timereportService.get(self.timereportId)
          self.date = self.datetimeToDate(self.timereport.start)
          self.start = self.datetimeToTime(self.timereport.start)
          self.end = self.datetimeToTime(self.timereport.end)
        } else {
          self.timereport.employee = self.employeeId
          self.timereport.occurrence = self.occurrence
          self.timereport.jobfunction = parseInt(self.jobfunction)
        }
        self.jobfunctions = jobfunctionService.getAll()
          .then(functions => {
            functions.forEach(element => {
              self.jobfunctionOptions.push({
                value: element.id,
                text: element.title,
              })
            })
          })

        self.employee = await employeeService.get(self.employeeId)
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async handleSubmit () {
      var self = this
      self.saving = true
      self.timereport.start = new Date(self.date + 'T' + self.start)
      self.timereport.end = new Date(self.date + 'T' + self.end)
      self.timereport.lunch = parseInt(self.lunch)
      if (self.timereport.end.getTime() < self.timereport.start.getTime()) {
        self.timereport.end  = new Date(self.timereport.end.getTime() + 24 * 60 * 60 * 1000)
      }
      try {
        if (self.timereport.id === undefined) {
          await timereportService.post(self.timereport)
        } else {
          await timereportService.put(self.timereport.id, self.timereport)
        }
      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.error=err
        self.saving = false
        return
      }
      self.saving = false
      self.timereport = {
        employee: undefined,
        description: undefined,
      }
      self.done()
    },
    done() {
      this.$emit('done')
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
}
</script>
