// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/event/'

export const eventService = {
  getAll,
  get,
  post,
  put,
};

async function getAll(customer_id, start, end) {
  const params = {
    customer: customer_id,
    start: start,
    end: end,
  }
  return getApiRequest(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

