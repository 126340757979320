<!-- Notifications.vue -->

<template>
  <div class="" v-if="notifications.length > 0">
    <b-list-group>
      <b-list-group-item
        v-for="item in notifications"
        class="d-flex justify-content-between align-items-center"
        :key="item.id">{{ item.description }}
        <b-button-group>
        <b-button
          v-if="item.new==true"
          title="Markera läst"
          v-b-tooltip.hover
          variant="outline-light"
          @click="readNotification(item)" >
          <b-icon icon="check" align-h="right" scale="1" variant="success"/>
        </b-button>
        <b-button
          title="Radera"
          v-b-tooltip.hover
          variant="outline-light"
          @click="deleteNotification(item)" >
          <b-icon icon="trash" align-h="right" scale="1" variant="danger"/>
        </b-button>
        </b-button-group>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>

import axios from "axios"

import { utils } from '../_mixins/utils.js'
import {
  notificationService,
} from '../_services';
export default {
  name: 'Notifications',
  mixins: [utils],
  props: {
    all: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      notifications: [],
    }
  },
  created () {
    var self = this
    self.fetch()
  },
  methods: {
    fetch() {
      var self = this
      try{
        notificationService.getAll(!self.all)
          .then(function(result){
            self.notifications = result
            self.$emit('notificationsLoaded', result.length);
          })

      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async readNotification(notification){
      notification.new = false
      await notificationService.put(notification.id, notification)
      this.fetch()
    },
    async deleteNotification(notification){
      await notificationService.remove(notification.id)
      this.fetch()
    },
  },
}
</script>
