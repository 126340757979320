export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null,
        title: null,
        url: null,
    },
    getters: {
      message: state => state.alert.message,
    },
    actions: {
        success({ commit }, { message, title, url }) {
            commit('success', {message, title, url});
        },
        error({ commit }, message, title) {
            commit('error', message, title);
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, {message, title, url}) {
          state.type = 'success';
          state.message = message;
          state.title = title;
          state.url = url;
        },
        error(state, message, title) {
          state.type = 'danger';
          state.message = message;
          state.title = 'Fel';
        },
        clear(state) {
          state.type = null;
          state.message = null;
          state.title = null;
          state.url = null;
        }
    }
}
