<template>
  <b-container fluid
    id="login" >
    <div class=""
      v-if="customer&&employee">
      <b-row class="justify-content-center mt-2">
        <b-col md="8">
          <b-button
            class="right-button"
            variant="primary"
            v-if="employee.user.is_staff"
            :to="{ name: 'customer_edit', params: {customerId: customerId}}">
            Editera
          </b-button>
          <b-button
            class="right-button"
            variant="primary"
            v-if="employee"
            :to="{ name: 'relative_message', params: {customerId: customerId}}">
            Skicka meddelande till anhöriga
          </b-button>
          <b-button
            class="right-button"
            variant="primary"
            v-if="employee.user.is_staff"
            :to="{ name: 'invoice', params: {customerId: customerId}}">
            Fakturering
          </b-button>
          <h1>Kund Information</h1>
          <h3><b-badge variant="danger" v-if="customer.active == false">Inaktiv</b-badge></h3>

          <b-table-simple hover small caption-top responsive>
            <b-tbody>
              <b-tr>
                <b-th>Namn</b-th>
                <b-td>{{ customer.firstname }} {{ customer.surname }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>E-Post</b-th>
                <b-td>
                  <a :href="'mailto:' + customer.email">{{ customer.email }}</a>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>Kön</b-th>
                <b-td>{{ customer.gender }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Personnummer</b-th>
                <b-td>{{ customer.ssn }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Födelsedag</b-th>
                <b-td>{{ customer.birthday }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Adress</b-th>
                <b-td class="description">
                  <a :href="mapLink()">{{ customer.address }}</a>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>Telefonnummer</b-th>
                <b-td>
                  <a :href="'tel:' + customer.phone_number">{{ customer.phone_number }}</a>
                </b-td>
              </b-tr>
              <b-tr v-if='customer.phone_number2'>
                <b-th>Telefonnummer 2</b-th>
                <b-td>
                  <a :href="'tel:' + customer.phone_number2">{{ customer.phone_number2 }}</a>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <hr/>
          <b-button
            class='button'
            variant="primary"
            :to="{ name: 'customer_calendar', params: {customerId: customerId}}">
            Kalender
          </b-button>
          <hr/>
          <b-button
            class='right-button'
            variant="primary"
            :to="{ name: 'careplan', params: {customerId: customerId.toString()}}">
            Editera Vårdplan
          </b-button>
          <h2>Vårdplan</h2>
          <p v-if="careplan" class='description'>{{ careplan.description }}</p>
          <b-list-group>
            <b-list-group-item
              v-for="item in filteredCareplanSteps()"
              :key="item.id">
              <b-row>
                <b-col>
                  {{ item.description }}
                </b-col>
                <b-col>
                  <b-button
                      class='right-button'
                      variant="primary"
                      @click='showData(item)'
                      >
                    Visa data
                  </b-button>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <hr/>
          <b-row align-h="between" class="mt-4">
            <b-col>
              <b-button size='lg' v-b-toggle.collapse-3 variant="primary">Journal
                <strong v-if="isVisibleJournal" aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-down-fill" scale="1"/>
                </strong>
                <strong v-else aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-up-fill" scale="1"/>
                </strong>
              </b-button>
            </b-col>
          </b-row>
          <b-collapse id="collapse-3" class="mt-2" v-model="isVisibleJournal">
            <journals :customerId="customerId" v-if="isVisibleJournal"/>
          </b-collapse>
          <b-row align-h="between" class="mt-4">
            <b-col>
              <b-button size='lg' v-b-toggle.collapse-1 variant="primary">Anhöriga
                <strong v-if="isVisibleRelative" aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-down-fill" scale="1"/>
                </strong>
                <strong v-else aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-up-fill" scale="1"/>
                </strong>
              </b-button>
              <b-collapse id="collapse-1" class="mt-2" v-model="isVisibleRelative">
                <b-row>
                  <b-col>
                    <b-button
                      class='right-button'
                      v-if="employee.user.is_staff"
                      variant="primary"
                      :to="{ name: 'relative_edit', params: {customerId: customerId, relativeId: 'new'}}">
                      Lägg till anhörig
                    </b-button>
                  </b-col>
                </b-row>
                <b-card v-for="item in relatives"
                        :key="item.id"
                        :title="relativeName(item.id)"
                        >
                        <b-card-text class="description">{{ item.address }}</b-card-text>
                        <b-card-text v-if='item.user'>
                          E-post: <a :href="'mailto:' + item.user.email">{{ item.user.email }}</a>
                        </b-card-text>
                        <b-card-text v-if='item.phone_number'>
                          Tel: <a :href="'tel:' + item.phone_number">{{ item.phone_number }}</a>
                        </b-card-text>
                        <b-card-text v-if='item.phone_number2'>
                          Tel 2: <a :href="'tel:' + item.phone_number2">{{ item.phone_number2 }}</a>
                        </b-card-text>
                        <b-button
                          class="right-button"
                          variant="primary"
                          v-if="employee.user.is_staff"
                          :to="{ name: 'relative_edit', params: {customerId: customerId, relativeId: item.id.toString()}}"
                          >
                          Editera
                        </b-button>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-4">
            <b-col>
              <b-button size='lg' v-b-toggle.collapse-messages variant="primary">Meddelanden till Anhöriga
                <strong v-if="isVisibleRelativeMessages" aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-down-fill" scale="1"/>
                </strong>
                <strong v-else aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-up-fill" scale="1"/>
                </strong>
              </b-button>
              <b-collapse id="collapse-messages" class="mt-2" v-model="isVisibleRelativeMessages">
                <div v-if="isVisibleRelativeMessages">
                  <relative-messages :customer-id="customerId"></relative-messages>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-4">
            <b-col>
              <b-button size='lg' v-b-toggle.collapse-2 variant="primary">Bilagor
                <strong v-if="isVisibleAttachment" aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-down-fill" scale="1"/>
                </strong>
                <strong v-else aria-hidden="true" class="ml-auto">
                  <b-icon icon="caret-up-fill" scale="1"/>
                </strong>
              </b-button>

              <b-collapse id="collapse-2" class="mt-2" v-model="isVisibleAttachment">
                <div v-if="isVisibleAttachment">
                  <form enctype="multipart/form-data" novalidate>
                    <h3>Ladda upp bilaga</h3>
                    <b-form-file
                      v-model="file1"
                      :name="uploadFieldName"
                      :state="Boolean(file1)"
                      placeholder="Välj en fil eller dra den hit..."
                      drop-placeholder="Släpp filen här..."
                      ></b-form-file>
                  </form>
                  <b-table hover stacked='md' :fields="attachmentFields" :items="attachments">
                    <template v-slot:cell(filename)="data">
                      {{ data.item.filename }}
                    </template>
                    <template v-slot:cell(edit)="data">
                      <b-button
                        variant="primary"
                        @click="downloadAttachment(data.item)"
                        >
                        <translate>Ladda ner</translate>
                      </b-button>
                      <b-button
                        variant="danger"
                        v-if="employee.user.is_staff"
                        @click="deleteAttachment(data.item)"
                        >
                        <translate>Radera</translate>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-3"
        >
        <b-col md="4">
        </b-col>
      </b-row>
    </div>

    <b-modal
        id="data-table-modal"
        size="lg"
        hide-footer>
      <care-plan-table :careplanstep="show_data" :customerId='customerId'/>
    </b-modal>
  </b-container>
</template>

<script>

import {
  customerService,
  customerAttachmentService,
  carePlanService,
  carePlanStepService,
  journalService,
  journalStepService,
  relativeService,
  employeeService,
} from '../_services';

import RelativeMessages from '../components/RelativeMessages'
import CarePlanTable from '../components/CarePlanTable'
import Journals from '../components/Journals'
import { utils } from '../_mixins/utils.js';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'CustomerPage',
  mixins: [utils],
  props: {
    customerId: {
      type: String,
    },
  },
  components: {
    RelativeMessages,
    Journals,
    CarePlanTable,
  },
  data () {
    return {
      customer: {
        'id': '0',
        'firstname': '',
        'surname': '',
        'email': '',
        'gender': '',
        'birthday': '',
        'phone_number': '',
      },
      careplans: [],
      careplan: undefined,
      careplansteps: [],
      journals: [],
      relatives: [],
      employees: undefined,
      file1: null,
      attachments: [],
      attachmentFields: [
        {key: 'filename', label: 'Namn'},
        {key: 'edit', label: ''},
      ],
      error: {},
      newCustomer: false,
      currentStatus: null,
      uploadFieldName: 'attachment',
      show_data: undefined,
      isVisibleJournal: false,
      isVisibleRelative: false,
      isVisibleRelativeMessages: false,
      isVisibleAttachment: false,
    }
  },
  created () {
    var self = this
    if (self.customerId != 'new'){
      self.fetch()
    } else {
      self.newCustomer = true
    }
    this.reset();

  },
  computed: {
    employee () {
      return this.$store.state.employee.employee
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  watch: {
    file1: function(val) {
      if(val) {
        this.filesChange(val)
      }
    }
  },
  methods: {
    filteredCareplanSteps() {
      let steps = this.careplansteps.filter((step) => {
        if (step.active == true) {
          return true
        }
        return false
      })
      return _.orderBy(
        steps,
        'order');
    },
    async fetch() {
      var self = this
      try {
        self.careplans = await carePlanService.getAll(self.customerId)
        if (self.careplans[0]) {
          // Load careplan
          self.careplan = self.careplans[0]
        }
        self.careplansteps = await carePlanStepService.getAll(self.customerId, true)
        self.relatives = await relativeService.getAll(self.customerId)
        self.customer = await customerService.get(self.customerId)
        self.employees = await employeeService.getAll(true)
        self.getAttachments()

      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.$router.push({ name: 'customers'})
      }
    },
    back() {
      this.$router.push({ name: 'customers'})
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    getAttachments() {
      var self = this
      customerAttachmentService.getAll(self.customerId)
        .then( results => {
          results.forEach(element => {
            element.filename = element.attachment.replace(/^.*[\\\/]/, '')
          })
          self.attachments = results
        })
    },
    downloadAttachment(attachment) {
        customerAttachmentService.get(attachment.id)
        .then( data => {
          if(data === false){
            return
          }
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', attachment.filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch( error => {
          console.error(error)
        })



    },
    deleteAttachment(attachment) {
        customerAttachmentService.remove(attachment.id)
        .then(result => {
          this.getAttachments()
        })
        .catch( error => {
          console.error(error)
        })
    },
    relativeName(Id) {
      var self = this
      var this_object = self.relatives.find(x => x.id === Id)
      return this_object.firstname + ' ' + this_object.surname
    },
    journalTime(start_string, end_string) {
      var start = new Date(start_string)
      var end = new Date(end_string)
      const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
      return start.toLocaleDateString('sv-SE', options) + ' ' + start.toLocaleTimeString('sv-SE') + ' - ' + end.toLocaleTimeString('sv-SE')
    },
    mapLink() {
      if(this.customer.address) {
        return "http://maps.google.com/maps?q=" + this.customer.address.replace(/\n/g, " ")
      }
      return ""
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      var self = this
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      customerAttachmentService.postAttachment(formData)
        .then(x => {
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
          this.file1 = null
          self.getAttachments()
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fileList) {
      // handle file changes
      const formData = new FormData()
      formData.append('customer', this.customer.id)

      // if (!fileList.length) return;

      // append the files to FormData
      // Array
      //   .from(Array(fileList.length).keys())
      //   .map(x => {
      formData.append('attachment', fileList, fileList.name);
      //   });

      // save it
      this.save(formData);
    },
    showData(careplanstep){
      this.show_data = careplanstep
      this.$bvModal.show('data-table-modal')
    },
  },
}
</script>
<style>
</style>
