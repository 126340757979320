// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/timereport/journalstep/'

export const journalStepService = {
  getAll,
  get,
  post,
  put,
};

async function getAll(customer_id, journal_id, careplanstep_id) {
  const params = {
    journal_id: journal_id,
    customer_id: customer_id,
    careplanstep_id: careplanstep_id,
  }
  return getApiRequest(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

