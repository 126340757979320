<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col lg="4" >
        <journal-edit
          :customerId="customerId"
          :journalId="journalId"
          v-on:done="back"
          />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import JournalEdit from '../components/JournalEdit'
import { utils } from '../_mixins/utils.js';

export default {
  name: 'JournalEditPage',
  mixins: [utils],
  props: {
    customerId: {
      type: String,
      default: undefined,
    },
    journalId: {
      type: String,
      default: undefined,
    },
  },
  components: {
    JournalEdit,
  },
}
</script>
