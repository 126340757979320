// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, postFileApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';
import { authHeader } from '../_helpers';

const path = '/customer/customer_attachment/'

export const customerAttachmentService = {
  getAll,
  get,
  post,
  postAttachment,
  put,
  remove,
};

async function getAll(customerId) {
  const params = {
    customer_id: customerId,
  }
  return getApiRequest(path, params)
}

function get(id) {
  var headers = authHeader()
  headers['Accept'] = 'application/octet-stream'
  var responseType = 'arraybuffer'
  return getApiRequest(path + id + '/', undefined, headers, responseType)
}

function post(data) {
  return postApiRequest(path, data, {})
}

function postAttachment(data) {
  return postFileApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

