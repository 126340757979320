// import config from 'config';
import axios from "axios";
import { putApiRequest } from '../_helpers';

const path = '/password_change'

export const passwordchangeService = {
  put,
};

function put(data) {
  return putApiRequest(path, data, {})
}

