import { employeeService } from '../_services';
import { router } from '../_helpers';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user, token: parseJwt(user.refresh)}
  : { status: {}, user: null, token: null };


function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { email, password }) {
      commit('loginRequest', { email });

      employeeService.login(email, password)
        .then(
          user => {
            commit('loginSuccess', user);
            dispatch('alert/clear', null, { root: true });
            dispatch('employee/loadEmployee', null, { root: true});
            router.push('/');
          },
          error => {
            console.error(error.response)
            commit('loginFailure', error);
            if (error.response.data.detail) {
              dispatch('alert/error', error.response.data.detail, { root: true });
            }else {
              dispatch('alert/error', error.response.data, { root: true });
            }
          }
        );
    },
    logout({ commit, dispatch }) {
      employeeService.logout();
      commit('logout');
      commit('employee/clearEmployee', null, {root: true});
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
      state.token = parseJwt(user.refresh)
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
      state.token = null;
      state.employee = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
      state.token = null;
      state.employee = null;
    }
  }
}
