<template id="">
  <div class="">

    <h2>{{ careplanstep.description }}</h2>

    <b-row>
      <b-col>
        <b-form-group
            label-for="input-start"
            :label="$gettext('Från')">
          <b-input-group>
            <b-form-input
                id="input-start"
                v-model="start"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                  v-model="start"
                  button-only
                  right
                  locale="sv"
                  v-bind="datepickerLabels['sv'] || {}"
                  start-weekday="1"
                  aria-controls="input-start"
                  @context="onContext"
                  ></b-form-datepicker>
            </b-input-group-append>
            <b-form-invalid-feedback
                v-for="item in error.date"
                :key="item"
                >
                {{ item }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
            label-for="input-end"
            :label="$gettext('Till')">
          <b-input-group>
            <b-form-input
                id="input-end"
                v-model="end"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                  v-model="end"
                  button-only
                  right
                  locale="sv"
                  v-bind="datepickerLabels['sv'] || {}"
                  start-weekday="1"
                  aria-controls="input-end"
                  @context="onContext"
                  ></b-form-datepicker>
            </b-input-group-append>
            <b-form-invalid-feedback
                v-for="item in error.date"
                :key="item"
                >
                {{ item }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>


    <b-table-simple
        v-if="careplanstep.steptype!=3"
        hover small caption-top responsive>
      <b-thead>
        <b-th colspan="2">Datum</b-th>
        <b-th colspan="3"></b-th>
      </b-thead>
      <b-tbody>
        <b-tr
            v-for="item in dataTable"
            :key="item.time">
          <b-td>{{ journalTime(item.x, item.end) }}</b-td>
          <b-td>
            <div v-if="careplanstep.steptype==1">
              <b-icon icon="check" scale="2" variant="success"/>
            </div>
            <div v-if="careplanstep.steptype==2">
              <span v-if="item.y==1">
                Ja
              </span>
              <span v-if="item.y==2">
                Nej
              </span>
            </div>
            <div v-if="careplanstep.steptype==4">
              {{ getChoice(careplanstep, item.y) }}
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <care-plan-data
        v-if="careplanstep.steptype==3 && loaded"
        :careplanstep="careplanstep" :customerId='customerId' :dataTable='dataTable'/>
  </div>

</template>

<script charset="utf-8">
import {
  carePlanStepService,
  journalService,
  journalStepService,
  stepChoiceService,
} from '../_services';
import { utils } from '../_mixins/utils.js';
import CarePlanData from '../components/CarePlanData'

export default {
  name: 'CarePlanTable',
  mixins: [utils],
  components: {
    CarePlanData,
  },
  props: {
    careplanstep: {
      type: Object,
    },
    customerId: {
      type: String,
    },
  },
  data () {
    const now = new Date()
    const today = this.toDate(now)
    const daysago = this.toDate(new Date().setDate(now.getDate() - 31));
    return {
      journalsteps: [],
      dataTable: [],
      stepchoices: [],
      loaded: false,
      start: daysago,
      end: today,
      error: {},
    }
  },
  watch: {
    start: function(val) {
      this.fetch()
    },
    end: function(val) {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    var self = this
  },
  methods: {
    async fetch () {
      var self = this
      var startDate = new Date(self.start + 'T00:00:00')
      var endDate = new Date(self.end + 'T23:59:59')
      self.dataTable = []
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return
      }
      self.journals = await journalService.getAll(self.customerId, null, null, startDate, endDate)
      self.journalsteps = await journalStepService.getAll(undefined, undefined, self.careplanstep.id)
      self.stepchoices = await stepChoiceService.getAll(self.careplanstep.id)
      self.journalsteps.forEach(async function(journalstep) {
        if (journalstep.value != null){

          var journal = self.journals.filter(obj => {
            return obj.active == true && obj.id == journalstep.journal
          })
          if (journal.length > 0){
            self.dataTable.push({
              'x': new Date(journal[0].start),
              'end': new Date(journal[0].end),
              'y': journalstep.value
            })
          }

        }
      })
      self.dataTable = self.dataTable.sort(function(a, b){return a.x-b.x});
      self.loaded = true
    },
    getChoice(step_id, value){
      var self = this
      var result = "error: " + parseInt(value)
      self.stepchoices.forEach(choice => {
        if (parseInt(choice.value) == parseInt(value)){
          result = choice.description
        }
      })
      return result
    },
  },


}
</script>
