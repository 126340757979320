import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { employee } from './employee.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    printView: false
  },
  modules: {
    alert,
    authentication,
    users,
    employee,
  }
});
