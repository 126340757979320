// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/organisation/'

export const organisationService = {
  getAll,
  get,
  post,
  put,
};

async function getAll() {
  return getApiRequest(path)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

