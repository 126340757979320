<template>
  <div v-if="employee">
    <b-row>
      <b-col>
        <b-form-group
            class='right-button'
            label-cols-md="8"
            label-align-md="right"
            content-cols-md="4"
            label-for="input-allEmployees"
            :label="$gettext('Visa alla')">
          <b-form-checkbox
              id="input-allEmployees"
              switch
              v-model="allEmployees"
              />
        </b-form-group>
        <b-form-group
            class='right-button'
            label-cols-md="8"
            label-align-md="right"
            content-cols-md="4"
            label-for="input-showTime"
            :label="$gettext('Visa tid')">
          <b-form-checkbox
              id="input-showTime"
              switch
              v-model="employee.settings_show_time"
              @change="changeShowTime($event)"
              />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="10" offset-lg="1" >
        <vue-cal
            id="vuecal"
            locale="sv"
            today-button
            :selected-date="selectedDate"
            :active-view="activeView"
            :time="employee.settings_show_time"
            :events="filteredOccurrences(employee.id)"
            :disable-views="['years','year']"
            :time-cell-height="timeCellHeight"
            :on-event-dblclick="onEventClick"
            :clickToNavigate="true"
            :dblclickToNavigate="false"
            @ready="calStarted"
            @view-change="calViewChanged"
            :style="calendarHeight">
          <template v-slot:event="{ event}">
            <small>
              <span v-if="allEmployees && event.employee"> {{ employeeName(event.employee) }} - </span>
              <span>{{ eventTitle(event) }}</span>
              <span v-if="!employee.settings_show_time">{{ journalTime(event.start, event.end, false)  }}</span>
            </small>
          </template>
        </vue-cal>
      </b-col>
          <b-col lg="1">
            <div class="text-right">
              <translate>Tidszon</translate>: {{ Intl.DateTimeFormat('sv-SE').resolvedOptions().timeZone }}
            </div>
          </b-col>
    </b-row>
    <hr/>

    <occurrence
        :occurrence="selectedOccurrence"
        v-on:save="updateCalendar"
        />
    <b-modal
        id="journal-modal"
        size="lg"
        hide-footer>
      <journal-edit
          v-if='selectedOccurrence.customer'
          :customerId="selectedOccurrence.customer.toString()"
          :journalId="getJournal()"
          :start_prop="selectedOccurrence.start"
          :end_prop="selectedOccurrence.end"
          :occurrence="selectedOccurrence.id"
          v-on:done="closeJournalModal()"
          />
    </b-modal>
    <b-modal
        id="timereport-modal"
        size="lg"
        hide-footer>
      <time-report
          v-if='!selectedOccurrence.customer && selectedOccurrence.employee'
          :employeeId="selectedOccurrence.employee.toString()"
          :timereportId="getTimeReport()"
          :start_prop="selectedOccurrence.start"
          :end_prop="selectedOccurrence.end"
          :occurrence="selectedOccurrence.id"
          :jobfunction="getJobFunction(selectedOccurrence)"
          v-on:done="closeTimeReportModal()"
          />
    </b-modal>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/sv.js';
import 'vue-cal/dist/vuecal.css';

import { utils } from '../_mixins/utils.js';
import Occurrence from '../components/Occurrence'
import JournalEdit from '../components/JournalEdit'
import TimeReport from '../components/TimeReport'
import {
  employeeService,
  customerService,
  journalService,
  jobfunctionService,
  timereportService,
} from '../_services';

export default {
  name: 'DlCalendar',
  mixins: [utils],
  components: {
    VueCal,
    Occurrence,
    JournalEdit,
    TimeReport,
  },
  data () {
    return {
      allEmployees: false,
      occurrences: [],
      lockOccurrences: false,
      pendingLoad: false,
      selectedOccurrence: {title: undefined},
      employees: undefined,
      customers: undefined,
      activeView: 'day',
      calendarHeight: 'height: 800px;',
      timeCellHeight: 50,
      selectedDate: undefined,
      journals: [],
      jobfunctions: undefined,
      polling: null,
    }
  },
  computed: {
    employee () {
      return this.$store.getters['employee/employee']
    },
    sortArrays(employee) {
      return _.orderBy(
        this.occurrences,
        'start', 'employee');
    },
  },
  watch: {
    employee: function(val) {
      var self = this
      this.fetch()
    },
    relative: function(val) {
      var self = this
      this.fetch()
    },
    allEmployees: function(val) {
      this.getOccurences()
    }
  },
  mounted () {
    var self = this
    self.calendarHeight = 'height: ' + screen.height * 0.60 + 'px;'
    if (screen.width < 500){
      self.activeView = 'day'
    } else {
      self.activeView = 'week'
    }
    self.fetch()
    self.pollData()
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  methods: {
    async fetch() {
      var self = this
      self.jobfunctions = await jobfunctionService.getAll()
      self.customers = await customerService.getAll(true)
      self.employees = await employeeService.getAll()
      self.loadTimeReports()
      self.loadJournals()
    },
    pollData () {
      var self = this
      self.polling = setInterval(function(){
        self.getOccurences()
      }, 10000);
    },
    loadJournals(employee_id) {
      var self = this
      try {
        journalService.getAll(undefined, employee_id, undefined,
          self.startDate, self.endDate,
        ).then(
          journals => {
            self.journals = journals
          })
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    loadTimeReports(){
      var self = this
      timereportService.getAll(
        self.$store.state.employee.employee.id,
        self.startDate,
        self.endDate,
        )
        .then(result =>{
          self.timereports = result
        })
    },
    filteredOccurrences(employee) {
      let filteredOccurrences = this.occurrences.filter((occurrence) => {
        return occurrence.employee === employee || this.allEmployees
      })
      return _.orderBy(
        filteredOccurrences,
        'start', 'employee');
    },
    calStarted (cal){
      var self = this
      self.calViewChanged(cal)
    },
    calViewChanged (cal){
      var self = this
      this.activeView=cal.view
      this.selectedDate=cal.startDate
      this.startDate = cal.startDate
      this.endDate = cal.endDate
      this.getOccurences(this.$store.state.employee.employee.id)
      setTimeout(self.scrollToCurrentTime, 500);
    },
    updateCalendar() {
      this.getOccurences(this.$store.state.employee.employee.id)
    },
    onEventClick (event, e) {
      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation()
      this.selectedOccurrence = event
      if (this.selectedOccurrence.customer != null) {
        this.$bvModal.show('journal-modal')
      }else{
        this.$bvModal.show('timereport-modal')
      }
    },
    async getOccurences(employee_id = this.$store.state.employee.employee.id){
      var self = this
      if (self.lockOccurrences === true){
        self.pendingLoad = true
        return
      }
      if (self.allEmployees){
        employee_id = undefined
      }
      self.lockOccurrences = true
      self.loadOccurences(
        null,
        self.startDate, self.endDate,
        employee_id,
      ).then(function(results) {
        self.occurrences = results
        self.lockOccurrences = false
        if (self.pendingLoad){
          // Reload as dates have been updated since last load started
          self.pendingLoad = false
          self.getOccurences()
        }
      })
    },
    getJournal() {
      var self = this
      let occurrence = self.selectedOccurrence.id
      var journal = self.journals.find(x => x.occurrence === occurrence)
      if (journal == undefined){
        return 'new'
      }
      return journal.id.toString()
    },
    getTimeReport() {
      var self = this
      let occurrence = self.selectedOccurrence.id
      var timereport = self.timereports.find(x => x.occurrence === occurrence)
      if (timereport == undefined){
        return 'new'
      }
      return timereport.id.toString()
    },
    getJobFunction(event){
      var self = this
      if (self.jobfunctions) {
        var jobfunction = self.jobfunctions.find(x => x.id === event.jobfunction)
        if (jobfunction) {

          return jobfunction.id.toString()
        }
      }
      return ''
    },
    closeJournalModal(){
      var self = this
      self.loadJournals(self.$store.state.employee.employee.id)
      this.$bvModal.hide('journal-modal')
    },
    closeTimeReportModal(){
      var self = this
      self.loadTimeReports()
      this.$bvModal.hide('timereport-modal')
    },
    changeShowTime(showTime){
      var self = this
      employeeService.put(self.employee.id, self.employee)
    },
  },
}
</script>
