<template>
  <div class="">
    <div v-if="success==false">

          <form
            class="box" >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              content-cols-sm
              content-cols-lg="9"
              label-for="input-email"
              :label="$gettext('Epost')">
              <b-form-input
                id="input-email"
                v-model="email"
                type="email"
                autocomplete="username"
                :state="is_ok('email', error.email)"
                :placeholder="$gettext('Fyll i din epost')"/>
                <b-form-invalid-feedback
                  v-if="error.email"
                  v-for="item in error.email"
                  :key="item">
                  {{ item }}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-button
              variant="primary"
              @click="handleSubmit()"
              >
              <translate>Återställ lösenord</translate>
            </b-button>
          </form>
    </div>
    <div v-if="success==true">
      Ett email har skickats till adressen
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { utils } from '../_mixins/utils.js';

export default {
  name: 'PasswordReset',
  mixins: [utils],
  data () {
    return {
      email: '',
      error: {},
      success: false
    }
  },
  methods: {
    async handleSubmit () {
      var self = this
      self.submitted = true;
      let requestOptions = {
        method: 'POST',
        data: {'email': self.email},
      };
      let response = await axios('/api/dj-rest-auth/password/reset/', requestOptions)
        .then(async function(result){
          self.success = true
          self.error = {}
        })
        .catch(async function(error){
          console.error(error.response)
          self.error = error.response.data
        })
    }
  }
}
</script>

