<template>
  <div id="app">
    <nav-bar />
    <Alert />
    <Update />
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    NavBar: () => import("./components/NavBar"),
    Update: () => import("./components/Update"),
    Alert: () => import("./components/Alert"),
  },
  computed: mapState(['alert/message']),
  mounted () {
    var self = this
    var user = this.$store.state.authentication.token
    if (user != null) {
      if (self.$store.state.employee.employee == undefined) {
        self.$store.dispatch('employee/loadEmployee');
      }
    }
  },
  methods: {
  },
}
</script>
<style>
</style>
