// import config from 'config';
import axios from "axios";
import {
  getApiRequest,
  getApiRequestAsync,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
} from '../_helpers';

const path = '/notification/'

export const notificationService = {
  getAll,
  get,
  post,
  put,
  remove,
};

function getAll(is_new) {
  const params = {
    is_new: is_new,
  }
  return getApiRequestAsync(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  const params = {
    is_new: false,
  }
  return deleteApiRequest(path + id + '/', params)
}

