// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, postFileApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/customer/'

export const customerService = {
  getAll,
  get,
  post,
  put,
  remove_future_occurrences,
};

async function getAll(deactivated=false) {
  const params = {
    deactivated: deactivated,
  }
  return getApiRequest(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

function remove_future_occurrences(id) {
  return deleteApiRequest(path + id + '/remove_future_occurrences/')
}

