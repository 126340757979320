<template>
  <b-container fluid
    id="login" >
    <b-row class="justify-content-center mt-3">
      <b-col md="4">
        <h1>DL Care</h1>

        <form
          class="box"
          @submit.prevent="handleSubmit">
          <b-form-group
            label-for="input-email"
            :label="$gettext('Epost')">
            <b-form-input
              id="input-email"
              v-model="email"
              type="email"
              autocomplete="username"
              :placeholder="$gettext('Fyll i din epost')"/>
          </b-form-group>
          <b-form-group
            label-for="input-password"
            :label="$gettext('Lösenord')">
            <b-form-input
              id="input-password"
              v-model="password"
              type="password"
              autocomplete="current-password"/>
          </b-form-group>
          <b-button
            variant="primary"
            type="submit"
            :disabled="loggingIn">
            <translate>Logga In</translate>
          </b-button>

          <b-button
            variant="warning"
            @click="PasswordReset()"
            >
            <translate>Glömt Lösenord</translate>
          </b-button>
        </form>

        <b-modal
          id="password-reset-modal"
          size="lg"
          title='Återställ Lösenord'
          hide-footer>
          <password-reset/>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PasswordReset from '../components/PasswordReset'
import { postApiRequest } from '../_helpers';
import { employeeService } from '../_services';
import { utils } from '../_mixins/utils.js';
export default {
  name: 'LoginPage',
  mixins: [utils],
  components: {
    PasswordReset,
  },
  data () {
    return {
      email: '',
      password: '',
      submitted: false
    }
  },
  computed: {
    loggingIn () {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  created () {
    // reset login status
    var self = this
    this.$store.dispatch('authentication/logout');
    if (typeof localStorage.webauthn_user != 'undefined' ){
      self.authenticate_webauthn()
    }
  },
  methods: {
    handleSubmit () {
      this.submitted = true;
      const { email, password } = this;
      const { dispatch } = this.$store;
      if (email && password) {
        this.$store.dispatch('authentication/login', { email, password });
      }
    },
    PasswordReset() {
      this.$bvModal.show('password-reset-modal')
    },

    transformAuthData (authDataFromServer) {
      var data = JSON.parse(atob(authDataFromServer.data))

      const key_id = Uint8Array.from(
        atob(data.publicKey.allowCredentials[0].id),
        c => c.charCodeAt(0)
      );

      const challenge = Uint8Array.from(
        atob(data.publicKey.challenge),
        c => c.charCodeAt(0));

      data.publicKey.allowCredentials[0].id = key_id
      data.publicKey.challenge = challenge

      return data;
    },
    transformAuthAssertionForServer (newAssertion) {
      var self = this

      const rawId = new Uint8Array(newAssertion.rawId)
      const authenticatorData = new Uint8Array(newAssertion.response.authenticatorData)
      const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON)
      const signature = new Uint8Array(newAssertion.response.signature)

      var base64clientData = btoa(String.fromCharCode.apply(null, clientDataJSON));

      return {
        id: newAssertion.id,
        rawId: self.uint8ToBase64(rawId),
        authenticatorData: self.uint8ToBase64(authenticatorData),
        clientDataJSON: base64clientData,
        signature: self.uint8ToBase64(signature),
      }
    },
    async authenticate_webauthn() {
      var self = this
      try {
        const begin_authentication = '/webauthn/begin_authentication/'
        const data = {
          'user': localStorage.webauthn_user,
        }
        let response = await postApiRequest(begin_authentication, data, {})
        const auth_data = self.transformAuthData(response);
        let assertion = await navigator.credentials.get(auth_data)
        const transformedAssertion = self.transformAuthAssertionForServer(assertion);
        const complete_authentication = '/webauthn/complete_authentication/'
        transformedAssertion.user = localStorage.webauthn_user
        response = await postApiRequest(
          complete_authentication,
          transformedAssertion,
          {}
        )
        employeeService.handleResponse({
          data: response,
          status: 200
        })
        this.$router.push({ name: 'home'})
        window.location.reload()
      } catch (err) {
        alert("Webauthn failed. Please login using password")
        console.error("Error creating credential:", err);
        return
      }

    },
  }
};
</script>
<style scoped>
</style>
