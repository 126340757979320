// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/employee/'

export const employeeService = {
  login,
  logout,
  getAll,
  get,
  post,
  put,
  handleResponse,
};

function login(email, password) {
    const requestOptions = {
      method: 'POST',
      url: '/api/token2/',
      headers: { 'Content-Type': 'application/json' },
      data: {
        email: email,
        password: password
      }
    };

    return axios(requestOptions)
        .then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}


function handleResponse(response) {
  const data = response.data

  if (response.status != "200") {
    console.log('error')
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  // login successful if there's a jwt token in the response
  if (data.access) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(data));
  }

  return data;
}

async function getAll(deactivated=false) {
  const params = {
    deactivated: deactivated,
  }
  return getApiRequest(path, params)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

