<!-- Occurrence.vue -->
<template>
    <b-modal
      id="occurrence-modal"
      size="lg"
      hide-footer>
        <div slot="modal-header">
          {{ occurrence.title }}
        <div v-if='occurrence.substitute_occurrence'>
          Vikariat
        </div>
        </div>
        <b-form
        class="box"
        @submit.prevent="handleSubmit">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-title"
            :label="$gettext('Titel')">
            <b-form-input
              id="input-title"
              v-model="occurrence.title"
              :state="is_ok('title')"
              type="text" />
              <b-form-invalid-feedback
                v-for="item in error.title"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="customers && customername && organisation.customers"
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-customer"
            :label="$gettext('Kund')">
            <b-form-input
              id="input-title"
              readonly
              v-model="customername"
              type="text" />
              <b-form-invalid-feedback
                v-for="item in error.title"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="jobfunctionname && organisation.job_functions"
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-jobfunction"
            :label="$gettext('Funktion')">
            <b-form-input
              id="input-jobfunction"
              readonly
              v-model="jobfunctionname"
              type="text" />
              <b-form-invalid-feedback
                v-for="item in error.jobfunction"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-date"
            :label="$gettext('Datum')">
            <b-input-group>
              <b-form-input
                id="input-date"
                v-model="date"
                :state="is_ok('date')"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="date"
                  button-only
                  right
                  locale="sv"
                  v-bind="datepickerLabels['sv'] || {}"
                  start-weekday="1"
                  aria-controls="input-date"
                  @context="onContext"
                  ></b-form-datepicker>
              </b-input-group-append>
              <b-form-invalid-feedback
                v-for="item in error.date"
                :key="item"
                >
                {{ item }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                label-align-sm="right"
                content-cols-sm
                content-cols-lg="9"
                label-for="input-start"
                :label="$gettext('Start')">
                <b-input-group class="mb-3">
                  <vue-timepicker
                    id="input-start"
                    v-model="start"
                    auto-scroll
                    manual-input
                    input-width="8em"
                    />
                    <b-form-invalid-feedback
                      v-for="item in error.start"
                      :key="item"
                      >
                      {{ item }}
                    </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                label-align-sm="right"
                content-cols-sm
                content-cols-lg="9"
                label-for="input-end"
                :label="$gettext('Slut')">
                <b-input-group class="mb-3">
                  <vue-timepicker
                    id="input-end"
                    v-model="end"
                    auto-scroll
                    manual-input
                    input-width="8em"
                    />
                    <b-form-invalid-feedback
                      v-for="item in error.end"
                      :key="item"
                      >
                      {{ item }}
                    </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-rule"
            :label="$gettext('Återkommande')">
            <v-select
              disabled
              v-model="event.rule"
              :options="ruleOptions"
              label="text"
              :reduce="object => object.value"
              >
            </v-select>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-slot="{ ariaDescribedby }"
            v-if="event.rule=='WEEKLY'"
            label-for="input-weekdays"
            :label="$gettext('Veckodagar')">
            <b-form-checkbox-group
              id="input-weekdays"
              v-model="event.weekdays"
              disabled
              :options="weekdayOptions"
              :aria-describedby="ariaDescribedby"
              name="weekdays"
              ></b-form-checkbox-group>
            <b-form-invalid-feedback
              :force-show="true"
              v-for="item in error.weekdays"
              :key="item"
              >
              {{ item }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-if="event.rule"
            label-for="input-end_recurring_period"
            :label="$gettext('Slutdatum')">
            <b-input-group>
              <b-form-input
                id="input-end_recurring_period"
                v-model="end_recurring_period"
                :state="is_ok('end_recurring_period')"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  id="end_recurring_period_datepicker"
                  v-model="end_recurring_period"
                  button-only
                  right
                  locale="sv"
                  v-bind="datepickerLabels['sv'] || {}"
                  start-weekday="1"
                  aria-controls="input-end_recurring_period"
                  ></b-form-datepicker>
              </b-input-group-append>
              <b-form-invalid-feedback
                v-for="item in error.end_recurring_period"
                :key="item"
                >
                {{ item }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-if="employees"
            label-for="input-employee"
            :label="$gettext('Personal')">
            <v-select
              id="input-employee"
              placeholder='Ingen personal vald'
              v-model="occurrence.employee"
              :options="employeeOptions"
              :reduce="object => object.value"
              label="text"
              >
            </v-select>
            <b-form-invalid-feedback
              :force-show="true"
              v-for="item in error.employee"
              :key="item">
              {{ item }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="occurrence.customer == null"
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-not-work"
            :label="$gettext('Ledig tid')">
            <b-form-checkbox
              id="input-not-work"
              v-model="occurrence.not_work"
              :state="is_ok('not_work', error.not_work)"
              switch
              />
              <b-form-invalid-feedback
                v-if="error.not_work"
                v-for="item in error.not_work"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if='occurrence.employee'
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-absence-logic"
            :label="$gettext('Frånvarande')">
            <b-form-checkbox
              id="input-absence-logic"
              v-model="absence_logic"
              :state="is_ok('absence_logic', error.absence_logic)"
              switch />
              <b-form-invalid-feedback
                v-if="error.absence"
                v-for="item in error.absence_logic"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="absence_logic"
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-absence"
            :label="$gettext('Frånvarotyp')">
            <v-select
              id="input-absence"
              placeholder='Ingen frånvarotyp vald'
              v-model="occurrence.absence"
              :options="absenceOptions"
              :reduce="object => object.value"
              label="text"
              :clearable="false"/>
              <!--
                Would be nice to have validation here.
                If absence_logic is checked, then absence must be selected.
                Since the current implementation accepts the form if absence_logic is
                checked without absence being selected. Would lead tobetter UX, since
                the user might get confused.
              -->
              <b-form-invalid-feedback
                :force-show="true"
                v-for="item in error.absence"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="occurrence.customer != null"
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-cancelled"
            :label="$gettext('Avbokat')">
            <b-form-checkbox
              id="input-cancelled"
              v-model="occurrence.cancelled"
              :state="is_ok('cancelled', error.cancelled)"
              switch
              />
              <b-form-invalid-feedback
                v-if="error.cancelled"
                v-for="item in error.cancelled"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="input-notify"
            :label="$gettext('Skicka notifiering')">
            <b-form-checkbox
              id="input-notify"
              v-model="occurrence.send_notification"
              :state="is_ok('notify', error.notify)"
              switch
              />
              <b-form-invalid-feedback
                v-if="error.notify"
                v-for="item in error.notify"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            v-if='occurrence.substitute_occurrence && !occurrence.employee'
            label-for="input-group"
            :label="$gettext('Vikariegrupp')">
            <v-select
              v-model="substituteGroup"
              :options="groupOptions"
              label="text"
              :reduce="option => option.value"
              >
            </v-select>
            <b-button
              v-if='substituteGroup'
              variant="warning"
              @click="message_substitutes"
              :disabled="sending">
              <translate>Meddela vikarier</translate>
            </b-button>
            <b-form-invalid-feedback
              :force-show="true"
              v-for="item in error.employee"
              :key="item">
              {{ item }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-row>
            <b-col  offset-md="3">
              <div class="">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="saving">
                  <translate>Spara</translate>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="secondary"
                  @click="close"
                  :disabled="saving">
                  <translate>Ångra</translate>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="danger"
                  v-b-modal.destroy-modal
                  :disabled="saving">
                  <translate>Radera</translate>
                </b-button>
                <b-modal id="destroy-modal" title="Vill du radera alla tider för bokningen?"
                                            hide-footer>
                  <div class="">
                    <b-button
                      class="ml-1"
                      variant="danger"
                      @click="destroy"
                      :disabled="saving">
                      <translate>Radera Alla</translate>
                    </b-button>
                    <b-button
                      class="ml-1"
                      variant="danger"
                      @click="changeDate"
                      :disabled="saving">
                      <translate>Radera efter</translate> {{ toDate(new Date()) }}
                    </b-button>
                    <b-button
                      class="ml-1"
                      @click="$bvModal.hide('destroy-modal')">
                      Ångra
                    </b-button>
                  </div>
                </b-modal>
              </div>
            </b-col>
          </b-row>
          </b-form>
    </b-modal>
</template>

<script>
import { utils } from '../_mixins/utils.js';
import { store } from '../_store';
import vSelect from 'vue-select';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {
  occurrenceService,
  eventService,
  customerService,
  jobfunctionService,
  employeeService,
  groupService,
  absencetypeService
} from '../_services';
export default {
  name: 'Occurrence',
  mixins: [utils],
  components: {
    vSelect,
    VueTimepicker,
  },
  props: ['occurrence'],
  data () {
    return {
      date: undefined,
      start: undefined,
      customers: undefined,
      customername: undefined,
      jobfunctions: undefined,
      groups: undefined,
      groupOptions: [],
      jobfunctionname: undefined,
      end: undefined,
      end_recurring_period: undefined,
      event: {},
      error: {},
      saving: false,
      sending: false,
      employees: undefined,
      employeeOptions: [],
      absenceTypes: undefined,
      absenceOptions: [],
      absence_logic: false,
      substituteGroup: undefined,
    }
  },
  computed: {
    organisation () {
      return  this.$store.getters['employee/organisation']
    },
  },
  watch: {
    occurrence: function(val){
      var self = this
      if (self.occurrence.absence === null) {
        self.absence_logic = false
      } else {
        self.absence_logic = true
      }
      self.date = self.datetimeToDate(val.start)
      self.start = self.datetimeToTime(val.start)
      self.end = self.datetimeToTime(val.end)
      self.error = {}
      eventService.get(self.occurrence.event_id)
        .then( event => {
          self.event = event
          self.customername = self.customerName(self.event)
          self.jobfunctionname = self.jobfunctionName(self.event)
          self.end_recurring_period = self.datetimeToDate(self.event.end_recurring_period)
        })
    },
  },
  created () {
    var self = this
    self.fetch()
  },
  methods: {
    async fetch() {
      var self = this
      try{
        self.customers = await customerService.getAll()
        self.jobfunctions = await jobfunctionService.getAll()
        self.employees = await employeeService.getAll()
        self.employees.forEach(element => {
          self.employeeOptions.push({
            value: element.id,
            text: element.user.surname + ', ' + element.user.firstname,
          })
        })
        self.absenceTypes = await absencetypeService.getAll()
        self.absenceTypes.forEach(element => {
          self.absenceOptions.push({
            value: element.id,
            text: element.description,
          })
        })
        self.groups = await groupService.getAll()
        self.groupOptions = []
        self.groups.forEach(element => {
          self.groupOptions.push({
            value: element.id,
            text: element.name,
          })
        })
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async handleSubmit () {
      var self = this
      self.saving = true
      self.error = {}
      self.occurrence.start = new Date(self.date + 'T' + self.start)
      self.occurrence.end = new Date(self.date + 'T' + self.end)
      if (self.occurrence.end.getTime() < self.occurrence.start.getTime()) {
        self.occurrence.end  = new Date(self.occurrence.end.getTime() + 24 * 60 * 60 * 1000)
      }
      if (!self.absence_logic) {
        self.occurrence.absence = null
      }
      try {
        if (self.occurrence.id === undefined) {
          await occurrenceService.post(self.occurrence)
        } else {
          await occurrenceService.put(self.occurrence.id, self.occurrence)
        }
      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.error = err
        // store.dispatch('alert/error', err, { root: true })
        self.saving = false
        return
      }

      self.event.end_recurring_period = new Date(self.end_recurring_period+ 'T' + self.end)
      try {
        await eventService.put(self.event.id, self.event)

      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.error = err
        self.saving = false
        return
      }

      self.$bvModal.hide('occurrence-modal')
      self.saving = false
      self.$emit('save')
    },
    close (){
      this.$bvModal.hide('occurrence-modal')
      this.error={}
    },
    destroyModal () {
      this.$bvModal.show('destroy-modal')
    },
    async destroy (){
      var self = this
      this.error={}
      await occurrenceService.remove(self.occurrence.id, self.occurrence)
      this.$emit('save')
      self.$emit('save')
      this.$bvModal.hide('occurrence-modal')
    },
    async changeDate(){
      var self = this
      const now = new Date()
      const currentEnd = new Date(self.event.end_recurring_period)
      if(now < currentEnd){
        self.event.end_recurring_period = now
        try {
          await eventService.put(self.event.id, self.event)

        }catch(err){
          console.error('Caught error')
          console.error(err)
          self.error = err
          self.saving = false
          return
        }
        self.$emit('save')
      }
      this.$bvModal.hide('occurrence-modal')

    },
    employeeName(employeeId) {
      var self = this
      var employee = self.employees.find(x => x.id === employeeId)
      if (employee) {
        return employee.user.firstname + ' ' + employee.user.surname
      }
      return ""
    },
    message_substitutes(){
      var self = this
      if(self.substituteGroup){
        self.sending = true
        occurrenceService.request_substitute(self.occurrence.id, self.substituteGroup)
        const message = 'Skickat'
        const title = ''
        const url = undefined
        self.$store.dispatch('alert/success', {message, title, url}, { root: true })
        self.sending = false
      }
    },
  },
}
</script>
