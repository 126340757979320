<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col md="8">
        <b-button
          class="right-button"
            variant="primary"
            :to="{ name: 'user', params: {userId: 'new'}}">
          Skapa Ny Personal
        </b-button>
        <h1>Personal</h1>
        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="7"
          label-align-sm="right"
          content-cols-sm="5"
          content-cols-md="5"
          label="Visa grupp"
          label-for="input-horizontal"
          >
          <v-select
              v-model="filtergroup"
              :options="groupOptions"
              :clearable="false"
              label="text"
              :reduce="option => option.value"
            >
          </v-select>
        </b-form-group>
        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="7"
          label-align-sm="right"
          content-cols-sm="5"
          content-cols-md="5"
          label="Visa inaktiva"
          label-for="input-horizontal"
          >
          <v-select
            v-model="filterinactive"
            :options="activeOptions"
            :clearable="false"
            label="text"
            :reduce="option => option.value"
            >
          </v-select>
        </b-form-group>
        <b-table
          hover
          :fields="fields"
          :items="users"
          filter="criteria"
          :filter-function="filterEmployees"
          >
          <template v-slot:cell(name)="data">
            {{ data.item.user.surname + ', ' +  data.item.user.firstname }}
          </template>
          <template v-slot:cell(edit)="data">
            <b-button-group>
              <b-button
                variant="primary"
                :to="{ name: 'user', params: {userId: data.item.id.toString()}}">
                Editera
              </b-button>
              <b-button
                variant="primary"
                :to="{ name: 'timereport', params: {employeeId: data.item.id.toString()}}">
                Tidrapportering
              </b-button>
              <b-button
                  v-if="organisation.approve_reporting"
                variant="primary"
                :to="{ name: 'timereportapprove', params: {employeeId: data.item.id.toString()}}">
                Godkänn tider
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { employeeService, groupService } from '../_services';
import vSelect from 'vue-select';

export default {
  name: 'UsersPage',
  components: {
    vSelect,
  },
  props: {
    groupId:{
      type: String,
      default: "false",
    },
  },
  data () {
    return {
      users: undefined,
      filtergroup: false,
      groups: undefined,
      groupOptions: [
        { value: false, text: 'Alla' },
      ],
      filterinactive: true,
      activeOptions: [
        { value: false, text: 'Ja' },
        { value: true, text: 'Nej' },
      ],
      fields: [
        {key: 'name', label: 'Namn'},
        {key: 'edit', label: ''},
      ],

    }
  },
  computed: {
    organisation () {
      return  this.$store.getters['employee/organisation']
    },
  },
  watch: {
    filtergroup: function(val){
    var self = this
      if (val){
        if (self.filtergroup != self.groupId){
          this.$router.push({ name: 'users-filtered', params: {groupId: val.toString()}})
        }
      } else {
        this.$router.push({ name: 'users'})
      }
    },
  },
  mounted () {
    var self = this
    if (self.groupId == "false"){
      self.filtergroup = false
    }else if (self.groupId != undefined){
      self.filtergroup = parseInt(self.groupId)
    }
    self.fetch()
    self.fetchGroups()
  },
  methods: {
    async fetch() {
      var self = this
      try{
        self.users = await employeeService.getAll(true)
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async fetchGroups() {
      var self = this
      self.groups = await groupService.getAll()
      self.groups.forEach(element => {
        self.groupOptions.push({
          value: element.id,
          text: element.name,
        })
      })
    },
    filterEmployees(row, filter) {
      if( this.filtergroup) {
        if (row.customer_groups.indexOf(this.filtergroup) === -1){
          return false
        }
      }
      if( this.filterinactive != row.active){
        return false
      }

      return true
    },
  },
}
</script>
