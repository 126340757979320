<template id="">
  <div class="">
    Alternativ:
    <b-row
        v-for="item in stepchoices"
        :key="item.id">

      <b-col class='pr-0'>
        {{ item.description }}
      </b-col>
      <b-col md="auto" class="pl-0">
        <b-button
            variant="danger"
            @click='removeChoice(item)'
            >
            Ta bort åtgärd
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class='pr-0'>
        <b-form-input
          id="input-title"
          v-model="newchoice"
          type="text" />
      </b-col>
      <b-col md="auto" class='pl-0'>
        <b-button
          @click='addChoice'
          variant="success"
          >
          Lägg till alternativ
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { stepChoiceService } from '../_services';
import { utils } from '../_mixins/utils.js';

export default {
  name: 'StepChoices',
  mixins: [utils],
  props: {
    step: {
      type: Object,
    },
  },
  data () {
    return {
      stepchoices: [],
      newchoice: '',
    }
  },
  created () {
    var self = this
    self.fetch()
  },
  methods: {
    async fetch() {
      var self = this
      try {
        self.stepchoices = await stepChoiceService.getAll(self.step.id)
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async addChoice(){
      var self = this
      var stepchoice = {
        'careplanstep': self.step.id,
        'description': self.newchoice,
      }
      try {
        await stepChoiceService.post(stepchoice)
        self.stepchoices = await stepChoiceService.getAll(self.step.id)
        self.newchoice = ''
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }

    },
    async removeChoice (stepchoice) {
      var self = this
      self.saving = true
      try {
        await stepChoiceService.remove(stepchoice.id)
        self.stepchoices = await stepChoiceService.getAll(self.step.id)
      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.saving = false
      }
      self.saving = false
    },
  }
}
</script>
