<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col
          md="12"
          v-if="employee&&customers&&organisation.id"
          >
          <b-tabs>
            <b-tab title="Kalender" lazy>
              <b-row mt-3>
                <b-col md="12" offset-md="0" >
                  <dl-calendar/>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Tidrapportering" v-if="organisation.timereporting">
              <b-row mt-3>
                <b-col md="8" offset-md="2" >
              <time-report
                :employeeId="employee.id.toString()"
                timereportId="new"
                />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="badge badge-success" v-if="nr_notifications">{{ nr_notifications }}</span>
                 Meddelanden
              </template>
              <b-row>
                <b-col md="8"  offset-md="2">
                  <notifications @notificationsLoaded="notificationsLoaded"/>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title='Översikt'>
              <b-row class="justify-content-center mt-3">
                <b-col md="8">
                  <h3>Kommande bokningar</h3>
                  <b-table
                      hover
                      v-if="occurrences"
                      :fields="fields"
                      :items="occurrences | myOccurrences( employee.id)"
                      sort-by="start"
                      >
                      <template v-slot:cell(date)="data">
                        {{ journalTime(data.item.start, data.item.end)  }}
                      </template>
                    <template v-slot:cell(customer)="data">
                      <b-link
                          v-if='data.item.customer'
                          :to="{ name: 'customer', params: {customerId: data.item.customer.toString()}}"
                          >
                          {{ customerName(data.item) }}
                      </b-link>
                        <div
                            v-if='!data.item.customer'
                            class="">
                          {{ data.item.title }}
                        </div>
                    </template>
                    <template v-slot:cell(time)="data">
                      {{ timeDiff(data.item.start, data.item.end).toFixed(2) }}
                    </template>
                  </b-table>
                  <hr/>
                  <h3>Tidigare inlägg:</h3>
                  <b-table
                      hover
                      :fields="fields"
                      :items="journals.slice(0, 5)"
                      sort-by="start"
                      :sort-desc="sortDesc"
                      >
                      <template v-slot:cell(date)="data">
                        {{ journalTime(data.item.start, data.item.end)  }}
                      </template>
                    <template v-slot:cell(customer)="data">
                      <b-link
                          v-if='data.item.customer'
                          :to="{ name: 'customer', params: {customerId: data.item.customer.toString()}}"
                          >
                          {{ customerName(data.item) }}
                      </b-link>
                    </template>
                    <template v-slot:cell(time)="data">
                      {{ timeDiff(data.item.start, data.item.end).toFixed(2) }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { utils } from '../_mixins/utils.js';
import { sub} from 'date-fns'
import {
  journalService,
  customerService,
  eventService,
  occurrenceService,
  employeeService,
  timereportService,
} from '../_services';
import Notifications from '../components/Notifications'
import DlCalendar from '../components/Calendar'
import TimeReport from '../components/TimeReport'

export default {
  name: 'HomePage',
  mixins: [utils],
  props: {
  },
  components: {
    Notifications,
    DlCalendar,
    TimeReport,
  },
  data () {
    return {
      journals: [],
      customers: undefined,
      events: undefined,
      occurrences: [],
      lockOccurrences: false,
      employees: undefined,
      startDate: undefined,
      endDate: undefined,
      fields: [
        {key: 'date', label: 'Datum'},
        {key: 'customer', label: 'Kund'},
        {key: 'time', label: 'Timmar'},
      ],
      sortDesc: true,
      nr_notifications: 0,
      polling: null,
    }
  },
  computed: {
    employee () {
      return this.$store.getters['employee/employee']
    },
    relative () {
      return this.$store.state.employee.relative
    },
    organisation () {
      return  this.$store.getters['employee/organisation']
    },
    sortArrays(employee) {
      return _.orderBy(
        this.occurrences,
        'start', 'employee');
    },
  },
  filters: {
    // Filter definitions
    myOccurrences(values, id) {
      return values.filter(function(element, index, self) {
        var start = new Date();
        start.setHours(0,0,0,0);
        if(element.start < start || element.working_time == true) {
          return false
        }
        return id == element.employee;
      });
    }
  },
  created () {
  },
  mounted () {
    var self = this
    self.fetch()
    self.pollData()
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  watch: {
    employee: function(val) {
      var self = this
      this.fetch()
    },
    relative: function(val) {
      var self = this
      this.fetch()
    },
  },
  methods: {
    async fetch() {
      var self = this
      self.startDate = new Date();
      self.startDate.setHours(0,0,0,0);

      if (self.$store.state.employee.employee != undefined){

        if (self.$store.state.employee.employee.user.force_password_change){
          self.$router.push({ name: 'password_change'})
        }
        self.loadJournals(self.$store.state.employee.employee.id)
        self.getOccurences(self.$store.state.employee.employee.id)
      }
      if (self.$store.state.employee.relative != undefined){
        this.$router.push({ name: 'homerelative'})
      }
      self.customers = await customerService.getAll(true)
      self.employees = await employeeService.getAll()
      self.loadTimeReports()
    },
    pollData () {
      var self = this
      self.polling = setInterval(function(){
        self.fetch()
      }, 30000);
    },
    loadJournals(employee_id) {
      var self = this
      try {
        const now = new Date()
        journalService.getAll(null, employee_id, undefined,
          sub(now, {months: 1})
        ).then(
          journals => {
            self.journals = journals
          })
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async getOccurences(employee_id){
      var self = this
      if (self.lockOccurrences === true){
        return
      }
      self.lockOccurrences = true
      self.loadOccurences(
        null,
        self.startDate, self.endDate,
        employee_id,
      ).then(function(results) {
        self.occurrences = results
        self.lockOccurrences = false
      })
    },
    loadTimeReports(){
      var self = this
      timereportService.getAll(
        self.$store.state.employee.employee.id,
        self.startDate,
        self.endDate,
        )
        .then(result =>{
          self.timereports = result
        })
    },
    employeeName(employeeId) {
      var self = this
      var employee = self.employees.find(x => x.id === employeeId)
      if (employee) {
        return employee.user.firstname + ' ' + employee.user.surname
      }
      return ""
    },
    notificationsLoaded(number){
      this.nr_notifications = number
    },
    changeShowTime(showTime){
      var self = this
      employeeService.put(self.employee.id, self.employee)
    },
  },
};
</script>
<style>
.employee-cal .vuecal__weekdays-headings {
  display: None;
}
.align-center {
  display: flex;
  align-items: center;
  text-align: right;
}
.align-right {
  text-align: right;
  width: 100%;
}

.nav-tabs {
  overflow: hidden !important;
}
.nav-tabs>li {
  float: none;
}

</style>
