import Vue from 'vue'
import { employeeService, relativeService, organisationService } from '../_services';
import { store } from '../_store';
import { router } from '../_helpers';


export const employee = {
  namespaced: true,
  state: {
    employee: undefined,
    organisation: {},
    relative: undefined,
  },
  getters: {
    organisation: state => {
      return state.organisation
    },
    employee: state => {
      return state.employee
    },
    relative: state => {
      return state.relative
    },
  },
  actions: {
    loadEmployee({ commit }) {
      var token =  store.state.authentication.token
      if (token != null && token != undefined){
        if (token.employee_id){
          employeeService.get(token.employee_id)
            .then(
              employee => {
                commit('setEmployee', employee)
                if (employee.user.organisation) {
                  organisationService.get(employee.user.organisation)
                  .then(
                    organisation => {
                      commit('setOrganisation', organisation)
                    }
                  )
                }
              },
              error => {
                console.error('Failed to load employee')
              })
        }
        if (token.relative_id){
          relativeService.get(token.relative_id)
            .then(
              relative => {
                commit('setRelative', relative)
              },
              error => {
                console.error('Failed to load relative')
              })
        }
      }
    },
  },
  mutations: {
    setEmployee(state, employee) {
      state.employee = employee
    },
    setOrganisation(state, organisation) {
      state.organisation = organisation
    },
    setRelative(state, relative) {
      state.relative = relative
    },
    clearEmployee(state) {
      state.employee = undefined
      state.organisation = {}
      state.relative = undefined
    },
  }
}
