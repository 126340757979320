<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col md="8">
        <h1>Vårdplaner</h1>
        <b-table hover :fields="fields" :items="customers">
          <template v-slot:cell(name)="data">
            {{ data.item.firstname + ' ' +  data.item.surname }}
          </template>
          <template v-slot:cell(edit)="data">
            <b-button
              variant="primary"
              :to="{ name: 'careplan', params: {customerId: data.item.id.toString()}}">
              Editera
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { customerService } from '../_services';

export default {
  name: 'CarePlansPage',
  data () {
    return {
      customers: undefined,
      fields: [
        {key: 'name', label: 'Namn'},
        {key: 'edit', label: 'Editera'},
      ],

    }
  },
  computed: {
  },
  mounted () {
    var self = this
    self.fetch()
  },
  methods: {
    async fetch() {
      var self = this
      try{
        self.customers = await customerService.getAll()
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    }
  },
}
</script>
