<template>
  <div v-if="customer" >
    <h1>Journalinlägg för {{ customer.firstname }} {{ customer.surname }}</h1>
    <form
      class="box"
      @submit.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-form-group
            label-for="input-date"
            :label="$gettext('Datum')">
            <b-input-group>
              <b-form-input
                id="input-date"
                v-model="date"
                :state="is_ok('date')"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="date"
                  :state="is_ok('date')"
                  button-only
                  right
                  locale="sv"
                  v-bind="datepickerLabels['sv'] || {}"
                  start-weekday="1"
                  aria-controls="input-date"
                  @context="onContext"
                  ></b-form-datepicker>
              </b-input-group-append>
              <b-form-invalid-feedback
                v-for="item in error.date"
                :key="item"
                >
                {{ item }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label-for="input-start"
                :label="$gettext('Start')">
                <b-input-group class="mb-3">
                  <vue-timepicker
                      id="input-start"
                      v-model="start"
                      auto-scroll
                      manual-input
                      input-width="8em"
                      />
                  <b-form-invalid-feedback
                      v-for="item in error.start"
                      :key="item"
                      >
                      {{ item }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="input-end"
                :label="$gettext('Slut')">
                <b-input-group class="mb-3">
                  <vue-timepicker
                      id="input-end"
                      v-model="end"
                      auto-scroll
                      manual-input
                      input-width="8em"
                      />
                  <b-form-invalid-feedback
                      v-for="item in error.end"
                      :key="item"
                      >
                      {{ item }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label-for="description"
            :label="$gettext('Beskrivning')">
            <b-form-textarea
              id="description"
              v-model="journal.description"
              :state="is_ok('description')"
              placeholder="Enter something..."
              rows="10"
              max-rows="60"
              ></b-form-textarea>
            <b-form-invalid-feedback
              v-for="item in error.description"
              :key="item">
              {{ item }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="Åtgärder:"
            v-if="journal.steps.length > 0"
            >
            <div class=""
                 v-for="item in journal.steps"
                 :key="item.id"
              >
              <b-form-checkbox
                v-if="item.steptype=='1'"
                v-model="item.value" name="check-button" switch>
                <b>{{ item.description }}</b>
              </b-form-checkbox>
            <b-form-group
                v-if="item.steptype=='2'"
                label-cols-sm="9"
                label-cols-lg="9"
                label-align-sm="left"
                content-cols-sm
                content-cols-lg="3"
                :label="item.description">
              <v-select
                  v-model="item.value"
                  :options="yesNoOptions"
                  label="text"
                  :reduce="object => object.value"/>
            </b-form-group>
            <b-form-group
                v-if="item.steptype=='3'"
                label-cols-sm="9"
                label-cols-lg="9"
                label-align-sm="left"
                content-cols-sm
                content-cols-lg="3"
                :label="item.description">
              <b-form-input
                  v-model="item.value"
                  type="text"
                  ></b-form-input>
            </b-form-group>
            <b-form-group
                v-if="item.steptype=='4'"
                label-cols-sm="9"
                label-cols-lg="9"
                label-align-sm="left"
                content-cols-sm
                content-cols-lg="3"
                :label="item.description">
              <v-select
                  v-model="item.value"
                  :options="stepchoices[item.step]"
                  label="description"
                  :reduce="object => object.value"/>
            </b-form-group>

            </div>
          </b-form-group>
          <b-form-group
            label-for="input-travel"
            :label="$gettext('Resedistans (km)')">
            <b-form-input
              id="input-travel"
              v-model="journal.travel"
              :state="is_ok('travel')"
              type="number"
              step="any"
              />
              <b-form-invalid-feedback
                v-for="item in error.travel"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-for="input-extra-expense"
            :label="$gettext('Extra utgifter')">
            <b-form-input
              id="input-extra-expense"
              v-model="journal.extra_expense"
              :state="is_ok('extra_expense')"
              type="text"
              />
              <b-form-invalid-feedback
                v-for="item in error.travel"
                :key="item">
                {{ item }}
              </b-form-invalid-feedback>
          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <b-col>

          <div class="">
            <b-button
              variant="primary"
              @click="handleSubmit(false)"
              :disabled="saving">
              <translate>Spara</translate>
            </b-button>
            <b-button
              class="ml-1"
              variant="primary"
              @click="handleSubmit(true)"
              :disabled="saving">
              <translate>Spara och Skapa ny</translate>
            </b-button>
            <b-button
              class="ml-1"
              variant="secondary"
              @click="done"
              :disabled="saving">
              <translate>Ångra</translate>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>

import vSelect from 'vue-select';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { utils } from '../_mixins/utils.js';
import {
  journalService,
  journalStepService,
  carePlanStepService,
  customerService,
  stepChoiceService,
} from '../_services';
import { is_ok } from '../_helpers';

export default {
  name: 'JournalEdit',
  mixins: [utils],
  components: {
    vSelect,
    VueTimepicker,
  },
  props: {
    customerId: {
      type: String,
      default: undefined,
    },
    journalId: {
      type: String,
      default: undefined,
    },
    start_prop: {
      type: Date,
      default: undefined,
    },
    end_prop: {
      type: Date,
      default: undefined,
    },
    occurrence: {
      type: Number,
      default: undefined,
    },
  },
  data () {
    return {
      customer: {},
      error: {},
      date: undefined,
      start: undefined,
      end: undefined,
      journal: {
        customer: undefined,
        description: undefined,
        steps: [],
      },
      careplansteps: [],
      stepchoices: [],
      saving: false,
      yesNoOptions: [
        { value: null, text: '' },
        { value: 1, text: 'Ja' },
        { value: 2, text: 'Nej' },
      ],

    }
  },
  created () {
    var self = this
    self.set_time()
    self.fetch()
  },
  computed: {
    user () {
      return this.$store.state.authentication.token
    },
  },
  methods: {
    is_ok(attribute){
      var self = this
      if (attribute in self.error){
        return false
      }
      return null
    },
    clear_form() {
      var self = this
      self.journalId = "new"
      self.customer = {}
      self.error = {}
      self.date = undefined
      self.start = undefined
      self.end = undefined
      self.journal = {
        customer: undefined,
        description: undefined,
        steps: [],
      }
      self.careplansteps = []
      self.stepchoices = []
      self.set_time()
      self.fetch()
    },
    set_time() {
      var self = this

      const now = new Date()
      let start_date = now
      if (self.start_prop == undefined) {
        self.start = self.datetimeToTime(now)
      }else {
        self.start = self.datetimeToTime(self.start_prop)
        start_date = self.start_prop
      }
      if (self.end_prop == undefined) {
        self.end = self.datetimeToTime(now)
      }else {
        self.end = self.datetimeToTime(self.end_prop)
      }

      var dt = start_date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      self.date = start_date.getFullYear() + '-' + (start_date.getMonth()+1).toString().padStart(2, "0") + '-' + dt
    },
    async fetch() {
      var self = this
      try {
        if (self.journalId != 'new'){
          self.journal = await journalService.get(self.journalId)
          self.date = self.datetimeToDate(self.journal.start)
          self.start = self.datetimeToTime(self.journal.start)
          self.end = self.datetimeToTime(self.journal.end)
          self.journal.steps = []
        } else {
          self.journal.employee = self.$store.state.employee.employee.id
          self.journal.customer = self.customerId
          self.journal.occurrence = self.occurrence
        }

        self.careplansteps = await carePlanStepService.getAll(self.customerId)
        var journalsteps = []
        if (self.journalId != 'new'){
          journalsteps = await journalStepService.getAll(null, self.journalId)
          for( const step of journalsteps) {
            let careplanstep = await carePlanStepService.get(step.careplanstep)
            if (self.careplansteps.findIndex(x => x.id==careplanstep.id) === -1) {
              self.careplansteps.push(careplanstep)
            }
          }
        }
        self.careplansteps.forEach(async function(careplanstep) {
          var value = undefined
          journalsteps.forEach(journalstep => {
            if (journalstep.careplanstep == careplanstep.id) {
              if(journalstep.value){
                value = parseFloat(journalstep.value)
                if(careplanstep.steptype == 1){
                  value = Boolean(value)
                }
              }
            }
          })
          self.journal.steps.push({
            'step': careplanstep.id,
            'value': value,
            'steptype': careplanstep.steptype,
            'description': careplanstep.description
          })
          self.stepchoices[careplanstep.id] = await stepChoiceService.getAll(careplanstep.id)
        })

        self.customer = await customerService.get(self.customerId)
      }catch(err){
        console.error('Caught error')
        console.error(err)
      }
    },
    async handleSubmit (create_new=false) {
      var self = this
      self.saving = true
      // self.journal.employee = self.$store.state.employee.employee.id
      self.journal.start = new Date(self.date + 'T' + self.start)
      self.journal.end = new Date(self.date + 'T' + self.end)
      if (self.journal.end.getTime() < self.journal.start.getTime()) {
        self.journal.end  = new Date(self.journal.end.getTime() + 24 * 60 * 60 * 1000)
      }
      try {
        if (self.journal.id === undefined) {
          await journalService.post(self.journal)
        } else {
          await journalService.put(self.journal.id, self.journal)
        }
        self.saving = false
        if (create_new){
          self.clear_form()
        }else{
          self.done()
        }
      }catch(err){
        console.error('Caught error')
        console.error(err)
        self.error=err
        self.saving = false
      }
    },
    done() {
      this.$emit('done')
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
}
</script>
