import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import LoginPage from '../views/LoginPage'
import UsersPage from '../views/UsersPage'
import UserPage from '../views/UserPage'
import CustomersPage from '../views/CustomersPage'
import CustomerPage from '../views/CustomerPage'
import CarePlansPage from '../views/CarePlansPage'
import CarePlanPage from '../views/CarePlanPage'
import JournalEditPage from '../views/JournalEditPage'
import { store } from '../_store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: '/homerelative',
    name: 'homerelative',
    component: () => import('../views/HomeRelativePage.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/password_reset/confirm/:uidb64/:token',
    name: 'password_reset_confirm',
    component: () => import('../views/PasswordResetConfirmPage.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/password_change/',
    name: 'password_change',
    component: () => import('../views/PasswordChangePage.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
  },
  {
    path: '/users/group/:groupId',
    name: 'users-filtered',
    component: UsersPage,
    props: true,
  },
  {
    path: '/users/:userId',
    name: 'user',
    component: UserPage,
    props: true,
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: () => import('../views/OrganisationsPage.vue'),
  },
  {
    path: '/organisations/:organisationId',
    name: 'organisation',
    component: () => import('../views/OrganisationPage.vue'),
    props: true,
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('../views/GroupsPage.vue'),
  },
  {
    path: '/groups/:groupId',
    name: 'group',
    component: () => import('../views/GroupPage.vue'),
    props: true,
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersPage,
  },
  {
    path: '/customers/group/:groupId',
    name: 'customers-filtered',
    component: CustomersPage,
    props: true,
  },
  {
    path: '/customers/:customerId',
    name: 'customer',
    component: CustomerPage,
    props: true,
  },
  {
    path: '/customers/edit/:customerId',
    name: 'customer_edit',
    component: () => import('../views/CustomerEditPage.vue'),
    props: true,
  },
  {
    path: '/careplans',
    name: 'careplans',
    component: CarePlansPage,
  },
  {
    path: '/careplans/:customerId',
    name: 'careplan',
    component: CarePlanPage,
    props: true,
  },
  {
    path: '/customers/:customerId/journal/:journalId',
    name: 'journal_edit',
    component: JournalEditPage,
    props: true,
  },
  {
    path: '/journals/:customerId',
    name: 'journals',
    component: () => import('../views/JournalsPage.vue'),
    props: true,
  },
  {
    path: '/journal_history/:uuid',
    name: 'journal_history',
    component: () => import('../views/JournalHistoryPage.vue'),
    props: true,
  },
  {
    path: '/journal_tags',
    name: 'journal_tags',
    component: () => import('../views/JournalTagsPage.vue'),
    props: true,
  },
  {
    path: '/journaltag/:journaltagId',
    name: 'journaltag',
    component: () => import('../views/JournalTagPage.vue'),
    props: true,
  },
  {
    path: '/journaltagrules',
    name: 'journaltagrules',
    component: () => import('../views/JournalTagRulesPage.vue'),
    props: true,
  },
  {
    path: '/journaltagrule/:tagruleId',
    name: 'journaltagrule',
    component: () => import('../views/JournalTagRulePage.vue'),
    props: true,
  },
  {
    path: '/customers/:customerId/relative/:relativeId',
    name: 'relative_edit',
    component: () => import('../views/RelativeEditPage.vue'),
    props: true,
  },
  {
    path: '/customers/:customerId/relative_message/',
    name: 'relative_message',
    component: () => import('../views/RelativeMessagePage.vue'),
    props: true,
  },
  {
    path: '/customers/:customerId/calendar/',
    name: 'customer_calendar',
    component: () => import('../views/CustomerCalendarPage.vue'),
    props: true,
  },
  {
    path: '/customers/:customerId/event/:eventId',
    name: 'customer_event_edit',
    component: () => import('../views/EventEditPage.vue'),
    props: true,
  },
  {
    path: '/event/:eventId',
    name: 'event_edit',
    component: () => import('../views/EventEditPage.vue'),
    props: true,
  },
  {
    path: '/invoice/:customerId',
    name: 'invoice',
    component: () => import('../views/InvoicePage.vue'),
    props: true,
  },
  {
    path: '/timereport/export',
    name: 'timeexport',
    component: () => import('../views/TimeExportPage.vue'),
  },
  {
    path: '/timereport/:employeeId',
    name: 'timereport',
    component: () => import('../views/TimeReportPage.vue'),
    props: true,
  },
  {
    path: '/timereport/approve/:employeeId',
    name: 'timereportapprove',
    component: () => import('../views/TimeReportApprovePage.vue'),
    props: true,
  },
  {
    path: '/schema',
    name: 'schema',
    component: () => import('../views/SchemaPage.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/NotificationsPage.vue'),
  },
  {
    path: '/jobfunctions',
    name: 'jobfunctions',
    component: () => import('../views/JobFunctionsPage.vue'),
  },
  {
    path: '/jobfunction/:jobfunctionId',
    name: 'jobfunction',
    component: () => import('../views/JobFunctionPage.vue'),
    props: true,
  },
  {
    path: '/absencetypes',
    name: 'absencetypes',
    component: () => import('../views/AbsenceTypesPage.vue'),
  },
  {
    path: '/absencetype/:absencetypeId',
    name: 'absencetype',
    component: () => import('../views/AbsenceTypePage.vue'),
  },
  {
    path: '/quickevents',
    name: 'quickevents',
    component: () => import('../views/QuickEventsPage.vue'),
  },
  {
    path: '/quickevent/:quickeventId',
    name: 'quickevent',
    component: () => import('../views/QuickEventPage.vue'),
  },
  {
    path: '/accept_substitute/:occurrenceId',
    name: 'acceptsubstitute',
    component: () => import('../views/AcceptSubstitutePage.vue'),
    props: true,
  }
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  var authRequired = true
  if (to.meta.requiresAuth === false) {
    authRequired = false
  }
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (store.state.employee.employee){
    if (store.state.employee.employee.user.force_password_change &&
      to.path != '/password_change'){
      return next('/password_change');
    }
  }

  next();
})

export default router;
