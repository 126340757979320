// import config from 'config';
import axios from "axios";
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/register-notif-token/'

export const firebaseService = {
  register,
};

function register(token) {
  const data = {
    registration_id: token,
    type: 'web'
  }
  const result =  postApiRequest(path, data, {})
    .catch(function(error){
      if (error.registration_id[0] == "This field must be unique.") {
        console.debug("Token already present.")
      } else {
        console.error(error)
      }

    })
      console.debug(result)
  return result
}
