// import config from 'config';
import axios from "axios";
import {
  getApiRequest,
  getApiRequestAsync,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
} from '../_helpers';

const path = '/occurrence/'

export const occurrenceService = {
  getAll,
  get,
  post,
  put,
  remove,
  request_substitute,
  accept_substitute,
};

function getAll(event_id, start, end, employee_id, customer_id) {
  const params = {
    event: event_id,
    start: start,
    end: end,
    employee_id: employee_id,
    customer_id: customer_id,
  }
  return getApiRequestAsync(path, params, 2)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id, data) {
  return deleteApiRequest(path + id + '/', data)
}

function request_substitute(id, substituteGroup) {
  const data = {
    occurrence: id,
    group: substituteGroup,
  }
  return postApiRequest(path + 'request_substitute/', data, {})
}
function accept_substitute(id) {
  const data = {
    occurrence: id,
  }
  return postApiRequest(path + 'accept_substitute/', data, {})
}
