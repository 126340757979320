import Q from 'q'
import { isSameDay, endOfDay, startOfDay, addDays} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import {
  eventService,
  occurrenceService,
} from '../_services';
export const utils = {
  data () {
    return {
      timezone: "Europe/Helsinki",
      datepickerLabels:  {
        sv: {
          labelPrevYear: 'Förra året',
          labelPrevMonth: 'Förra månaden',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nästa månad',
          labelNextYear: 'Nästa år',
          labelToday: 'Idag',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Inget datum valt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Använd piltangenterna för att navigera i kalendern'
        },
      },
      weekdayOptions: [
        { text: 'Må', value: 'MO' },
        { text: 'Ti', value: 'TU' },
        { text: 'On', value: 'WE' },
        { text: 'To', value: 'TH' },
        { text: 'Fr', value: 'FR' },
        { text: 'Lö', value: 'SA' },
        { text: 'Sö', value: 'SU' },
      ],
      ruleOptions: [
        { value: null, text: 'Nej' },
        { value: 'DAILY', text: 'Daglig' },
        { value: 'WEEKLY', text: 'Veckovis' },
        { value: 'MONTHLY', text: 'Månadsvis' },
      ],
    }
  },
  methods: {
    back: function() {
      this.$router.go(-1)
    },

    is_ok: function(attribute) {
      var self = this
      if (attribute in self.error){
        return false
      }
      return null
    },

    onContext: function(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },

    timeDiff: function (start, end) {
      var diff = (new Date(end)).getTime() - (new Date(start)).getTime()
      var hours = diff/(1000*3600)
      return hours
    },

    datetimeToDate: function (datestring){
      var date = new Date(datestring)
      var year = date.getFullYear();
      var month = (date.getMonth()+1).toString().padStart(2, "0");
      var day = date.getDate().toString().padStart(2, "0")

      return year + '-' + month + '-' + day
    },
    datetimeToTime: function (datestring){
      var date = new Date(datestring)
      var hour = date.getHours().toString().padStart(2, "0");
      var minute = date.getMinutes().toString().padStart(2, "0");
      return hour + ':' + minute
    },
    toDate: function (datetime) {
      return format(datetime, 'y-MM-dd')
    },
    toTime: function (datetime) {
      let local = new Date(datetime)
      return local.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })
    },
    toWeekNumber: function(datetime) {
      var oneJan = new Date(datetime.getFullYear(),0,1);
      var numberOfDays = Math.floor((datetime - oneJan) / (24 * 60 * 60 * 1000));
      var result = Math.ceil(( datetime.getDay() + 1 + numberOfDays) / 7);
      return result
    },
    journalTime: function (start_string, end_string, date=true) {
      var start = new Date(start_string)
      var end = new Date(end_string)
      // var start = utcToZonedTime(new Date(start_string), this.timezone)
      //var end = utcToZonedTime(new Date(end_string), this.timezone)
      const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
      var date_string = ''
      if (date) {
        date_string = this.toDate(start)
      }
      return date_string + ' ' + format(start, 'HH:mm') + ' - ' + format(end, 'HH:mm')
    },

    scrollToCurrentTime: function () {
      const now = new Date()
      var hour = now.getHours();
      const calendar = document.querySelector('#vuecal .vuecal__bg')
      if (calendar){
        calendar.scrollTo({ top: hour * this.timeCellHeight, behavior: 'auto' })
      }
    },

    loadOccurences: function (customerId, startDate, endDate, employeeId){
      return new Promise((resolve, reject) => {
        var occurrences = []
        occurrenceService.getAll(
          null,
          startDate,
          endDate,
          employeeId,
          customerId,
        )
          .then(function(result) {
            if (result.data == false){
              return []
            }
            result.forEach(occurrence => {
              occurrence.start=new Date(occurrence.start)
              occurrence.end=new Date(occurrence.end)
              if (occurrence.employee == null){
                occurrence.class="employee-unset"
              }
              if (occurrence.cancelled == true){
                occurrence.class="cancelled"
              }
              if (occurrence.working_time == true){
                occurrence.class="working_time"
                // occurrence.background = true
              }
              if (occurrence.absence !== null){
                occurrence.class="absence"
              }
              if (occurrence.substitute == true){
                occurrence.class="substitute_occurrence"
              }
              occurrences.push(occurrence)
            })
            resolve(occurrences)
          })
        .catch(function(error) {
          console.error(error)
        })
      })
    },
    eventTitle(event) {
      var self = this
      if (self.customers) {
        var customer = self.customers.find(x => x.id === event.customer)
        if (customer) {
          return customer.firstname + ' ' + customer.surname
        }
      }
      if (self.jobfunctions) {
        var jobfunction = self.jobfunctions.find(x => x.id === event.jobfunction)
        if (jobfunction) {
          return jobfunction.title
        }
      }
      if (self.groups) {
        var group = self.groups.find(x => x.id === event.group)
        if (group) {
          return group.name
        }
      }
      return event.title
    },
    customerName(event) {
      var self = this
      if (self.customers) {
        var customer = self.customers.find(x => x.id === event.customer)
        if (customer) {
          return customer.firstname + ' ' + customer.surname
        }
      }
      return ''
    },
    jobfunctionName(event) {
      var self = this
      if (self.jobfunctions) {
        var jobfunction = self.jobfunctions.find(x => x.id === event.jobfunction)
        if (jobfunction) {
          return jobfunction.title
        }
      }
      return ''
    },
    employeeName(employeeId) {
      var self = this
      var employee = self.employees.find(x => x.id === employeeId)
      if (employee) {
        return employee.user.firstname + ' ' + employee.user.surname
      }
      return ""
    },
    uint8ToBase64 (uint8) {
      var i
      var extraBytes = uint8.length % 3 // if we have 1 byte left, pad 2 bytes
      var output = ''
      var temp, length

      function encode (num) {
        var lookup = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
        return lookup.charAt(num)
      }

      function tripletToBase64 (num) {
        return encode(num >> 18 & 0x3F) + encode(num >> 12 & 0x3F) + encode(num >> 6 & 0x3F) + encode(num & 0x3F)
      }

      // go through the array every three bytes, we'll deal with trailing stuff later
      for (i = 0, length = uint8.length - extraBytes; i < length; i += 3) {
        temp = (uint8[i] << 16) + (uint8[i + 1] << 8) + (uint8[i + 2])
        output += tripletToBase64(temp)
      }

      // pad the end with zeros, but make sure to not forget the extra bytes
      switch (extraBytes) {
        case 1:
          temp = uint8[uint8.length - 1]
          output += encode(temp >> 2)
          output += encode((temp << 4) & 0x3F)
          output += '=='
          break
        case 2:
          temp = (uint8[uint8.length - 2] << 8) + (uint8[uint8.length - 1])
          output += encode(temp >> 10)
          output += encode((temp >> 4) & 0x3F)
          output += encode((temp << 2) & 0x3F)
          output += '='
          break
        default:
          break
      }

      return output
    },
    taggedTime(report, tag) {
      var self = this
      if (tag == null) {
        return self.timeDiff(report.start, report.end)
      }
      var time = 0
      report.tags.forEach(report_tag => {
        if (report_tag.id != tag.id) {
          return
        }
        let rule_time = report_tag.time
        time += rule_time
      })
      return time
    },
    ruleTime(startDate, endDate, rule){
      var self = this
      let timeDiff = self.timeDiff(startDate, endDate)
      if (timeDiff <= 0) {
        return 0
      }

      if (!isSameDay(startDate, endDate)){
        const firstDay = self.ruleTime(startDate, endOfDay(startDate), rule)
        const restDays = self.ruleTime(startOfDay(addDays(startDate, 1)), endDate, rule)
        return firstDay + restDays
      }


      var startDay = (startDate.getDay() + 6) % 7 + 1
      var endDay = (endDate.getDay() + 6) % 7 + 1
      if (startDay == 1 && !rule.mondays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (startDay == 2 && !rule.tuesdays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (startDay == 3 && !rule.wednesdays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (startDay == 4 && !rule.thursdays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (startDay == 5 && !rule.fridays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (startDay == 6 && !rule.saturdays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (startDay == 7 && !rule.sundays){
        return self.ruleTime(self.tomorrow(startDate), endDate, rule)
      }
      if (endDay == 1 && !rule.mondays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }
      if (endDay == 2 && !rule.tuesdays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }
      if (endDay == 3 && !rule.wednesdays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }
      if (endDay == 4 && !rule.thursdays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }
      if (endDay == 5 && !rule.fridays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }
      if (endDay == 6 && !rule.saturdays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }
      if (endDay == 7 && !rule.sundays){
        return self.ruleTime(startDate, self.yesterday(endDate), rule)
      }

      let ruleStart = new Date(format(startDate, 'yyyy-MM-dd') + 'T' + rule.start)
      if(ruleStart.getTime() > startDate.getTime()){
        startDate = ruleStart
      }

      let ruleEndDate = new Date(startDate)
      if (rule.end < rule.start){
        ruleEndDate.setDate(ruleEndDate.getDate() + 1)
      }
      let ruleEnd = new Date(format(ruleEndDate, 'yyyy-MM-dd') + 'T' + rule.end)
      if(ruleEnd.getTime() < endDate.getTime()){
        endDate = ruleEnd
      }

      timeDiff = self.timeDiff(startDate, endDate)
      if (timeDiff > 0) {
        return timeDiff
      }
      return 0
    },
    tomorrow(date){
      var tomorrow = new Date(date)
      return startOfDay(addDays(tomorrow, 1))
    },
    yesterday(date){
      var yesterday = new Date(date)
      return endOfDay(subDays(yesterday, 1))
    },
    time_convert(num) {
      var total_minutes = num * 60;
      var hours = Math.floor(total_minutes / 60);
      var minutes = Math.round(total_minutes % 60)
      return hours + ":" + minutes;
    },
    filterEmployees(row, filter) {
      if( this.filtergroup > -1) {
        if (row.customer_groups.indexOf(this.filtergroup) === -1){
          return false
        }
      }
      return true
    },
  }
}
