<template>
  <div class="">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            content-cols-sm
            content-cols-lg="9"
            label-for="webauthn-button"
            :label="$gettext('Fingeravtrycks-inloggning')">
            <b-button
              id="webauthn-button"
              variant="primary"
              @click="create_webauthn()"
              >
              <translate>Registrera webauthn</translate>
            </b-button>
          </b-form-group>

  </div>

</template>

<script charset="utf-8">
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest, decode} from '../_helpers';
import { utils } from '../_mixins/utils.js';

export default {
  name: 'WebAuthn',
  mixins: [utils],
  created() {
    var self = this
    self.fetch()
  },
  data () {
    return {
      options: '',
      error: {},
      success: false
    }
  },
  computed: {
    user () {
      return this.$store.state.authentication.token
    },
  },
  methods: {
    async fetch () {
      let requestOptions = {
        method: 'GET',
      };
      const path = '/webauthn/get_options/'
      let response = getApiRequest(path)
        .then(function(result){
        })
    },
    transformCredentialCreateOptions (credentialCreateOptionsFromServer) {
      var data = JSON.parse(atob(credentialCreateOptionsFromServer.data))
      var publicKey = data.publicKey
      let {challenge, user} = publicKey;

      user.id = Uint8Array.from(
        publicKey.user.id
        ,
        c => c.charCodeAt(0));

      challenge = Uint8Array.from(
        atob(publicKey.challenge),
        c => c.charCodeAt(0));

      const transformedCredentialCreateOptions = Object.assign(
        {}, publicKey,
        {challenge});

      return transformedCredentialCreateOptions;
    },
    transformNewAssertionForServer (newAssertion) {
      var self = this
      // var data = atob(newAssertion)


      const attObj = new Uint8Array(
        newAssertion.response.attestationObject);
      var string = String.fromCharCode.apply(null, attObj)
      var base64AttObj = btoa(String.fromCharCode.apply(null, attObj));
      const clientDataJSON = new Uint8Array(
        newAssertion.response.clientDataJSON);
      const rawId = new Uint8Array(
        newAssertion.rawId);

      const registrationClientExtensions = newAssertion.getClientExtensionResults();

      return {
        id: new Uint8Array(newAssertion.id),
        rawId: rawId,
        type: newAssertion.type,
        attObj: base64AttObj,
        clientData: self.uint8ToBase64(clientDataJSON),
        registrationClientExtensions: JSON.stringify(registrationClientExtensions)
      };
    },
    async create_webauthn() {
      var self = this
      const path = '/webauthn/get_options/'
      let options = await getApiRequest(path)

      const set_path = '/webauthn/set_credentials/'
      try {
        const publicKeyCredentialCreateOptions = self.transformCredentialCreateOptions(options);
        publicKeyCredentialCreateOptions.attestation = 'direct'
        navigator.credentials.create({ publicKey: publicKeyCredentialCreateOptions })
          .then(function(newCredentialInfo) {
            const newAssertionForServer = self.transformNewAssertionForServer(newCredentialInfo);
            let response = postApiRequest(set_path, newAssertionForServer, {})
              .then(function(result){
                localStorage.webauthn_user = self.user.user_id;
              })
          }).catch( function(error) {
            localStorage.removeItem('webauthn_user')
            console.error(error)
            alert("Webauthn failed. Please login using password")
          })
      } catch (err) {
        alert("Webauthn failed. Please login using password")
        console.error(error)
        localStorage.removeItem('webauthn_user')
        return console.error("Error creating credential:", err);
      }
    },
  },
}
</script>
