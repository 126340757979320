// import config from 'config';
import axios from "axios";
import { getApiRequest, getApiRequestAsync, postApiRequest, putApiRequest, deleteApiRequest } from '../_helpers';

const path = '/quickevent/'

export const quickeventService = {
  getAll,
  get,
  post,
  put,
  remove,
  useQuickevent
};

function getAll() {
  return getApiRequestAsync(path)
}

function get(id) {
  return getApiRequest(path + id + '/')
}

function post(data) {
  return postApiRequest(path, data, {})
}

function put(id, data) {
  return putApiRequest(path + id + '/', data, {})
}

function remove(id) {
  return deleteApiRequest(path + id + '/')
}

function useQuickevent(id, employee, date) {
  const data = {
    quickevent: id,
    employee: employee,
    date: date,
  }
  return postApiRequest(path + 'create_event/', data, {})
}
